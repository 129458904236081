import { Layout } from 'antd';
import { FRONTEND_VERSION } from '../address.component';
import './footer.styles.css';

const { Footer } = Layout;

const FooterComponent = () => {
  return (
    <div id="footer-component">
      <Footer>
        <div className="footer-grid">
          <span className="footer-copyright-text">
            Copyright &copy; 2022
            <span className="footer-company"> Alpaca Systems </span>| All rights
            reserved.
          </span>
          <span className="footer-version-text">{FRONTEND_VERSION || "VERSION NOT FOUND"}</span>
        </div>
      </Footer>
    </div>
  );
};

export default FooterComponent;
