import React from 'react';
import { Layout } from 'antd';
import Header from '../../components/header/header.component';
import FooterComponent from '../../components/footer/footer.component';
import 'antd/dist/antd.css';
import { withRouter } from 'react-router-dom';
import Login from './login.component';
import './login.styles.css';
const { Content } = Layout;

const LoginPage = withRouter(() => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header />
      <Layout style={{ height: 0 }}>
        <Layout className="site-layout" style={{ height: '100%' }}>
          <Content style={{ overflow: 'auto' }}>
            <Login />
          </Content>
        </Layout>
      </Layout>
      <FooterComponent />
    </Layout>
  );
});

export default LoginPage;
