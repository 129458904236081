import React from "react";
import "antd/dist/antd.css";
import "./datagridAnt.css";
// import "../../header/header.styles.css";
import { Input, Modal } from "antd";
import { PageContainer } from "@ant-design/pro-layout";
import ProTable from "@ant-design/pro-table";
import axios from "axios";
import { ReloadOutlined, CloseOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { Tooltip } from "antd";
import { connect } from "react-redux";
import Success from "./../../../assets/success.png";
import Error from "./../../../assets/error.png";
import { ConfigProvider, Form, Button, Select, DatePicker } from "antd";
import { store } from "../../../redux/store";
import { withRouter } from "react-router-dom";
import { DISCONNECT } from "../../../redux/types";
import address from "../../address.component";
import moment from "moment";
const { TextArea } = Input;
const { Search } = Input;
async function disconnectWithSSO(history) {
  console.log('in AntVersion disconnect');
  store.dispatch({
    type: DISCONNECT,
  });
  history.push("/");
}

class WebReceipt extends React.Component {
  formRef = React.createRef();
  state = {
    isloading: true,
    POdataSource: [],
    POdata: [],
    paramList: [],
    noPOdata: false,
    value: "",
    newQuantity: "",
    msg: "",
    showSubModal: false,
    showPostModal: false,
    partInfo: [],
    PL_Count: 0,
    msg_type: false,
    selectedRow: "",
    selectedPart: "",
    POStatus: "",
    itemdata: [],
    selectedPO: "",
    isItem: false,
    itemChanged: false,
    subchanged: false,
    updatedPO: "",
    isposting: false,
    submsg: "",
    showModalLoading: false,
    status: 0,
    showModalMessage: false,
    showModalEmail: false,
    noPOsubdata: false,
    subdataSource: [],
    subdata: [],
    storedQuantity: 0,
    emailBody: "",
    emailSubject: "",
    emailSent: 0,
    emailSentSuccessful: 0,
    subValue: "",
    subcolumnchanged: false,
    packingarray: [],
    packingitemArray: [],
    selectedMO: "",
    helpDeskEmail: "helpdesk@alpacasys.com",
    type: "checkbox",
    isSubloading: false,
    isdataLoading: true,
    filterdata: [],
    search: true,
    isSending: false,
    machiningTime: "",
    grossWt: "",
    PORow: "",
    itemdataSource: [],
    isItemLoading: false,
    showItemModal: false,
    selectedItemPart: "",
    itemValue: "",
    noItemData: false,
    selectedItem: "",
    Nofiltered: "",
    Descfiltered: "",
    Vendorfiltered: "",
    Divfiltered: "",
    Statusfiltered: "",
    itemdesc: "",
    itemvendor: "",
    itemtype: "",
    itempart: "",
    itempartdesc: "",
    itemdiv: "",
    itemqty: "",
    itemqtyrcd: "",
    itemqtyrem: "",
    itemunit: "",
    expiryTime: "",
    showSignoutModal: false,
    isTimedOut: false,
    lotNo: "",
    allLocations: [],
    location: "",
    lotNoMandatory: false,
    locationMandatory: false,
    date: moment(),
    IsPOLineFullyRcd:false
  };
  componentDidMount() {
    console.log('in mount');
    console.log(this.props.division);
    const { history } = this.props;
    this.setState({ expiryTime: localStorage.getItem("EReceiptuser") }, () => {
      var today = new Date();
      if (
        new Date(this.state.expiryTime) <= new Date(today) ||
        this.state.expiryTime === "" ||
        this.state.expiryTime === null
      ) {
        disconnectWithSSO(history);
        localStorage.setItem("EReceiptuser", "");
      } else {
        var timeremainingModal =
          new Date(this.state.expiryTime) - Date.now() - 120000;
        setTimeout(() => {
          this.showSignoutModal();
        }, timeremainingModal);
      }
    });
    axios
    .get(`${address}/nav/getLocations`, {
      headers: {
        Authorization: localStorage.getItem("beartoken"),
      },
    })
    .then((res) => {
      this.setState({ allLocations: res.data },()=>{
        console.log(this.state.allLocations);
      });
    })
    .catch((error) => {
      if (error.response.status === 401) {
        localStorage.removeItem("EReceiptuser");
        store.dispatch({
          type: DISCONNECT,
        });
        this.props.history.push("/login");
      }
    });
if(this.props.division!='' && this.props.division!=undefined ){
    axios
      .post(`${address}/nav/unique`,{Division:this.props.division}, {
        headers: {
          Authorization: localStorage.getItem("beartoken"),
        },
      })
      .then((res) => {
        if (res.data === "No") {
          this.setState({
            isloading: false,
            noPOdata: true,
            POdata: [],
            POdataSource: [],
            isdataLoading: false,
          });
        } else {
          this.setState({
            isloading: false,
            noPOdata: false,
            POdata: res.data,
            POdataSource: res.data,
            isdataLoading: false,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("EReceiptuser");
          store.dispatch({
            type: DISCONNECT,
          });
          this.props.history.push("/login");
        }
      });
    }else{
      this.setState({
        isloading: false,
        noPOdata: true,
        POdata: [],
        POdataSource: [],
        isdataLoading: false,
      });
    }
  }

  componentDidUpdate(prevProps){
      if (this.props.division !== prevProps.division) {
        this.setState({noPOdata:false},()=>{
        this.Reload();
        });
      }
  }
  handleKeepLoggedIn = () => {
    this.setState({ isTimedOut: false, showSignoutModal: false }, () => {
      var today = new Date();
      var expirydate = new Date(
        new Date(today).setHours(today.getHours() + 12)
      );
      localStorage.setItem("EReceiptuser", expirydate);
      this.setState(
        { expiryTime: localStorage.getItem("EReceiptuser") },
        () => {
          var timeremainingModal =
            new Date(this.state.expiryTime) - Date.now() - 120000;
          setTimeout(() => {
            this.showSignoutModal();
          }, timeremainingModal);
        }
      );
    });
  };
  handleLogout = () => {
    this.setState({ isTimedOut: false, showSignoutModal: false }, () => {
      const { history } = this.props;
      disconnectWithSSO(history);
      localStorage.setItem("EReceiptuser", "");
    });
  };
  checkLogout = () => {
    if (this.state.isTimedOut) {
      const { history } = this.props;
      disconnectWithSSO(history);
      localStorage.setItem("EReceiptuser", "");
    }
  };

  showSignoutModal = () => {
    this.setState({ showSignoutModal: true, isTimedOut: true }, () => {
      setTimeout(() => {
        this.checkLogout();
      }, 120000);
    });
  };

  closeSignoutModal = () => {
    this.setState({ showSignoutModal: false });
  };

  onFinish = (values) => {
    var Message = values["Message"].replace(/[\r\n]/g, "<br/>");
    values = { ...values, Message };
    this.sendEmail(values);
  };

  onReset = () => {
    this.formRef.current.resetFields();
  };

  handleOpenModalMessage = () => {
    this.setState({ showModalMessage: true });
  };

  handleCloseModalMessage = () => {
    this.setState({ showModalMessage: false });
  };

  Reload = () => {
    if(this.props.division!='' && this.props.division!=undefined){
    this.setState({
      isloading: true,
      emailSubject:"",
      POdata: [],
      POdataSource: [],
      isdataLoading: true,
    },()=>{
      //this.onReset();
    });
    axios
      .post(`${address}/nav/unique`,{Division:this.props.division}, {
        headers: {
          Authorization: localStorage.getItem("beartoken"),
        },
      })
      .then((res) => {
        if (res.data === "No") {
          this.setState({
            isloading: false,
            noPOdata: true,
            POdata: [],
            POdataSource: [],
            isdataLoading: false,
          });
        } else {
          var data = res.data;
          const newdata = data.filter(
            ({
              No,
              Posting_Description,
              Buy_from_Vendor_Name,
              Dim1Code,
              Status,
            }) => {
              No = No.toLowerCase();
              Posting_Description = Posting_Description.toLowerCase();
              Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
              Dim1Code = Dim1Code.toLowerCase();
              Status = Status.toLowerCase();
              return (
                No.includes(this.state.Nofiltered) &&
                Posting_Description.includes(this.state.Descfiltered) &&
                Buy_from_Vendor_Name.includes(this.state.Vendorfiltered) &&
                Dim1Code.includes(this.state.Divfiltered) &&
                Status.includes(this.state.Statusfiltered)
              );
            }
          );
          data = newdata;
          const filteredData = data.filter(
            ({
              No,
              Posting_Description,
              Buy_from_Vendor_Name,
              Dim1Code,
              Status,
            }) => {
              Posting_Description = Posting_Description.toLowerCase();
              Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
              No = No.toLowerCase();
              Dim1Code = Dim1Code.toLowerCase();
              Status = Status.toLowerCase();
              var currValuelower = this.state.value.toLowerCase();
              return (
                Posting_Description.toString().includes(currValuelower) ||
                Buy_from_Vendor_Name.includes(currValuelower) ||
                Dim1Code.includes(currValuelower) ||
                No.includes(currValuelower) ||
                Status.includes(currValuelower)
              );
            }
          );

          this.setState({
            POdataSource: filteredData,
            isloading: false,
            POdata: res.data,
            noPOdata: false,
            isdataLoading: false,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("EReceiptuser");
          store.dispatch({
            type: DISCONNECT,
          });
          this.props.history.push("/login");
        }
      });
    }
  };

  findItemDesc = (event) => {
    var data = this.state.itemdata;
    this.setState({ itemdesc: event.target.value.toLowerCase() }, () => {
      if (this.state.itemValue !== "") {
        const newdata = this.state.itemdata.filter(
          ({ Description, No, Buy_from_Vendor_Name }) => {
            Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
            Description = Description.toLowerCase();
            No = No.toLowerCase();
            var currValuelower = this.state.itemValue.toLowerCase();
            return (
              Buy_from_Vendor_Name.includes(currValuelower) ||
              Description.includes(currValuelower) ||
              No.includes(currValuelower)
            );
          }
        );

        data = newdata;
      }
      const filteredData = data.filter(
        ({
          PostingDescription,
          Buy_from_Vendor_Name,
          FormatType,
          Description,
          Dim1Code,
          No,
          Quantity,
          Quantity_Received,
          QtyOutstanding,
          Unit_of_Measure,
        }) => {
          FormatType = FormatType.toLowerCase();
          Description = Description.toLowerCase();
          Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
          No = No.toLowerCase();
          PostingDescription = PostingDescription.toLowerCase();
          Unit_of_Measure = Unit_of_Measure.toLowerCase();
          Dim1Code = Dim1Code.toLowerCase();
          return (
            PostingDescription.toString().includes(this.state.itemdesc) &&
            Buy_from_Vendor_Name.includes(this.state.itemvendor) &&
            No.includes(this.state.itempart) &&
            Description.includes(this.state.itempartdesc) &&
            FormatType.includes(this.state.itemtype) &&
            Dim1Code.includes(this.state.itemdiv) &&
            Quantity.toString().includes(this.state.itemqty) &&
            Quantity_Received.toString().includes(this.state.itemqtyrcd) &&
            QtyOutstanding.toString().includes(this.state.itemqtyrem) &&
            Unit_of_Measure.includes(this.state.itemunit)
          );
        }
      );
      if (event.target.value === "") {
        document.getElementById(
          "itemdesc"
        ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
          "";
        document.getElementById(
          "itemdescInput"
        ).parentNode.parentNode.style.backgroundColor = "";
      } else {
        document.getElementById(
          "itemdesc"
        ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
          "lightblue";
        document.getElementById(
          "itemdescInput"
        ).parentNode.parentNode.style.backgroundColor = "lightblue";
      }
      this.setState({
        itemdataSource: filteredData,
        isItemLoading: false,
        noItemData: false,
      });
    });
  };

  findItemVendor = (event) => {
    var data = this.state.itemdata;
    this.setState({ itemvendor: event.target.value.toLowerCase() }, () => {
      if (this.state.itemValue !== "") {
        const newdata = this.state.itemdata.filter(
          ({ Description, No, Buy_from_Vendor_Name }) => {
            Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
            Description = Description.toLowerCase();
            No = No.toLowerCase();
            var currValuelower = this.state.itemValue.toLowerCase();
            return (
              Buy_from_Vendor_Name.includes(currValuelower) ||
              Description.includes(currValuelower) ||
              No.includes(currValuelower)
            );
          }
        );

        data = newdata;
      }
      const filteredData = data.filter(
        ({
          PostingDescription,
          Buy_from_Vendor_Name,
          FormatType,
          Description,
          Dim1Code,
          No,
          Quantity,
          Quantity_Received,
          QtyOutstanding,
          Unit_of_Measure,
        }) => {
          FormatType = FormatType.toLowerCase();
          Description = Description.toLowerCase();
          Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
          No = No.toLowerCase();
          PostingDescription = PostingDescription.toLowerCase();
          Unit_of_Measure = Unit_of_Measure.toLowerCase();
          Dim1Code = Dim1Code.toLowerCase();
          return (
            PostingDescription.toString().includes(this.state.itemdesc) &&
            Buy_from_Vendor_Name.includes(this.state.itemvendor) &&
            No.includes(this.state.itempart) &&
            Description.includes(this.state.itempartdesc) &&
            FormatType.includes(this.state.itemtype) &&
            Dim1Code.includes(this.state.itemdiv) &&
            Quantity.toString().includes(this.state.itemqty) &&
            Quantity_Received.toString().includes(this.state.itemqtyrcd) &&
            QtyOutstanding.toString().includes(this.state.itemqtyrem) &&
            Unit_of_Measure.includes(this.state.itemunit)
          );
        }
      );
      if (event.target.value === "") {
        document.getElementById(
          "itemvendor"
        ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
          "";
        document.getElementById(
          "itemvendorInput"
        ).parentNode.parentNode.style.backgroundColor = "";
      } else {
        document.getElementById(
          "itemvendor"
        ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
          "lightblue";
        document.getElementById(
          "itemvendorInput"
        ).parentNode.parentNode.style.backgroundColor = "lightblue";
      }
      this.setState({
        itemdataSource: filteredData,
        isItemLoading: false,
        noItemData: false,
      });
    });
  };

  findItemType = (event) => {
    var data = this.state.itemdata;
    this.setState({ itemtype: event.target.value.toLowerCase() }, () => {
      if (this.state.itemValue !== "") {
        const newdata = this.state.itemdata.filter(
          ({ Description, No, Buy_from_Vendor_Name }) => {
            Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
            Description = Description.toLowerCase();
            No = No.toLowerCase();
            var currValuelower = this.state.itemValue.toLowerCase();
            return (
              Buy_from_Vendor_Name.includes(currValuelower) ||
              Description.includes(currValuelower) ||
              No.includes(currValuelower)
            );
          }
        );

        data = newdata;
      }
      const filteredData = data.filter(
        ({
          PostingDescription,
          Buy_from_Vendor_Name,
          FormatType,
          Description,
          Dim1Code,
          No,
          Quantity,
          Quantity_Received,
          QtyOutstanding,
          Unit_of_Measure,
        }) => {
          FormatType = FormatType.toLowerCase();
          Description = Description.toLowerCase();
          Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
          No = No.toLowerCase();
          PostingDescription = PostingDescription.toLowerCase();
          Unit_of_Measure = Unit_of_Measure.toLowerCase();
          Dim1Code = Dim1Code.toLowerCase();
          return (
            PostingDescription.toString().includes(this.state.itemdesc) &&
            Buy_from_Vendor_Name.includes(this.state.itemvendor) &&
            No.includes(this.state.itempart) &&
            Description.includes(this.state.itempartdesc) &&
            FormatType.includes(this.state.itemtype) &&
            Dim1Code.includes(this.state.itemdiv) &&
            Quantity.toString().includes(this.state.itemqty) &&
            Quantity_Received.toString().includes(this.state.itemqtyrcd) &&
            QtyOutstanding.toString().includes(this.state.itemqtyrem) &&
            Unit_of_Measure.includes(this.state.itemunit)
          );
        }
      );
      if (event.target.value === "") {
        document.getElementById(
          "itemtype"
        ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
          "";
        document.getElementById(
          "itemtypeInput"
        ).parentNode.parentNode.style.backgroundColor = "";
      } else {
        document.getElementById(
          "itemtype"
        ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
          "lightblue";
        document.getElementById(
          "itemtypeInput"
        ).parentNode.parentNode.style.backgroundColor = "lightblue";
      }
      this.setState({
        itemdataSource: filteredData,
        isItemLoading: false,
        noItemData: false,
      });
    });
  };

  findItemPart = (event) => {
    var data = this.state.itemdata;
    this.setState({ itempart: event.target.value.toLowerCase() }, () => {
      if (this.state.itemValue !== "") {
        const newdata = this.state.itemdata.filter(
          ({ Description, No, Buy_from_Vendor_Name }) => {
            Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
            Description = Description.toLowerCase();
            No = No.toLowerCase();
            var currValuelower = this.state.itemValue.toLowerCase();
            return (
              Buy_from_Vendor_Name.includes(currValuelower) ||
              Description.includes(currValuelower) ||
              No.includes(currValuelower)
            );
          }
        );

        data = newdata;
      }
      const filteredData = data.filter(
        ({
          PostingDescription,
          Buy_from_Vendor_Name,
          FormatType,
          Description,
          Dim1Code,
          No,
          Quantity,
          Quantity_Received,
          QtyOutstanding,
          Unit_of_Measure,
        }) => {
          FormatType = FormatType.toLowerCase();
          Description = Description.toLowerCase();
          Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
          No = No.toLowerCase();
          PostingDescription = PostingDescription.toLowerCase();
          Unit_of_Measure = Unit_of_Measure.toLowerCase();
          Dim1Code = Dim1Code.toLowerCase();
          return (
            PostingDescription.toString().includes(this.state.itemdesc) &&
            Buy_from_Vendor_Name.includes(this.state.itemvendor) &&
            No.includes(this.state.itempart) &&
            Description.includes(this.state.itempartdesc) &&
            FormatType.includes(this.state.itemtype) &&
            Dim1Code.includes(this.state.itemdiv) &&
            Quantity.toString().includes(this.state.itemqty) &&
            Quantity_Received.toString().includes(this.state.itemqtyrcd) &&
            QtyOutstanding.toString().includes(this.state.itemqtyrem) &&
            Unit_of_Measure.includes(this.state.itemunit)
          );
        }
      );
      if (event.target.value === "") {
        document.getElementById(
          "itempart"
        ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
          "";
        document.getElementById(
          "itempartInput"
        ).parentNode.parentNode.style.backgroundColor = "";
      } else {
        document.getElementById(
          "itempart"
        ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
          "lightblue";
        document.getElementById(
          "itempartInput"
        ).parentNode.parentNode.style.backgroundColor = "lightblue";
      }
      this.setState({
        itemdataSource: filteredData,
        isItemLoading: false,
        noItemData: false,
      });
    });
  };

  findItemPartDesc = (event) => {
    var data = this.state.itemdata;
    this.setState({ itempartdesc: event.target.value.toLowerCase() }, () => {
      if (this.state.itemValue !== "") {
        const newdata = this.state.itemdata.filter(
          ({ Description, No, Buy_from_Vendor_Name }) => {
            Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
            Description = Description.toLowerCase();
            No = No.toLowerCase();
            var currValuelower = this.state.itemValue.toLowerCase();
            return (
              Buy_from_Vendor_Name.includes(currValuelower) ||
              Description.includes(currValuelower) ||
              No.includes(currValuelower)
            );
          }
        );

        data = newdata;
      }
      const filteredData = data.filter(
        ({
          PostingDescription,
          Buy_from_Vendor_Name,
          FormatType,
          Description,
          Dim1Code,
          No,
          Quantity,
          Quantity_Received,
          QtyOutstanding,
          Unit_of_Measure,
        }) => {
          FormatType = FormatType.toLowerCase();
          Description = Description.toLowerCase();
          Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
          No = No.toLowerCase();
          PostingDescription = PostingDescription.toLowerCase();
          Unit_of_Measure = Unit_of_Measure.toLowerCase();
          Dim1Code = Dim1Code.toLowerCase();
          return (
            PostingDescription.toString().includes(this.state.itemdesc) &&
            Buy_from_Vendor_Name.includes(this.state.itemvendor) &&
            No.includes(this.state.itempart) &&
            Description.includes(this.state.itempartdesc) &&
            FormatType.includes(this.state.itemtype) &&
            Dim1Code.includes(this.state.itemdiv) &&
            Quantity.toString().includes(this.state.itemqty) &&
            Quantity_Received.toString().includes(this.state.itemqtyrcd) &&
            QtyOutstanding.toString().includes(this.state.itemqtyrem) &&
            Unit_of_Measure.includes(this.state.itemunit)
          );
        }
      );
      if (event.target.value === "") {
        document.getElementById(
          "itempartdesc"
        ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
          "";
        document.getElementById(
          "itempartdescInput"
        ).parentNode.parentNode.style.backgroundColor = "";
      } else {
        document.getElementById(
          "itempartdesc"
        ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
          "lightblue";
        document.getElementById(
          "itempartdescInput"
        ).parentNode.parentNode.style.backgroundColor = "lightblue";
      }
      this.setState({
        itemdataSource: filteredData,
        isItemLoading: false,
        noItemData: false,
      });
    });
  };

  findItemDiv = (event) => {
    var data = this.state.itemdata;
    this.setState({ itemdiv: event.target.value.toLowerCase() }, () => {
      if (this.state.itemValue !== "") {
        const newdata = this.state.itemdata.filter(
          ({ Description, No, Buy_from_Vendor_Name }) => {
            Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
            Description = Description.toLowerCase();
            No = No.toLowerCase();
            var currValuelower = this.state.itemValue.toLowerCase();
            return (
              Buy_from_Vendor_Name.includes(currValuelower) ||
              Description.includes(currValuelower) ||
              No.includes(currValuelower)
            );
          }
        );

        data = newdata;
      }
      const filteredData = data.filter(
        ({
          PostingDescription,
          Buy_from_Vendor_Name,
          FormatType,
          Description,
          Dim1Code,
          No,
          Quantity,
          Quantity_Received,
          QtyOutstanding,
          Unit_of_Measure,
        }) => {
          FormatType = FormatType.toLowerCase();
          Description = Description.toLowerCase();
          Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
          No = No.toLowerCase();
          PostingDescription = PostingDescription.toLowerCase();
          Unit_of_Measure = Unit_of_Measure.toLowerCase();
          Dim1Code = Dim1Code.toLowerCase();
          return (
            PostingDescription.toString().includes(this.state.itemdesc) &&
            Buy_from_Vendor_Name.includes(this.state.itemvendor) &&
            No.includes(this.state.itempart) &&
            Description.includes(this.state.itempartdesc) &&
            FormatType.includes(this.state.itemtype) &&
            Dim1Code.includes(this.state.itemdiv) &&
            Quantity.toString().includes(this.state.itemqty) &&
            Quantity_Received.toString().includes(this.state.itemqtyrcd) &&
            QtyOutstanding.toString().includes(this.state.itemqtyrem) &&
            Unit_of_Measure.includes(this.state.itemunit)
          );
        }
      );
      if (event.target.value === "") {
        document.getElementById(
          "itemdiv"
        ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
          "";
        document.getElementById(
          "itemdivInput"
        ).parentNode.parentNode.style.backgroundColor = "";
      } else {
        document.getElementById(
          "itemdiv"
        ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
          "lightblue";
        document.getElementById(
          "itemdivInput"
        ).parentNode.parentNode.style.backgroundColor = "lightblue";
      }
      this.setState({
        itemdataSource: filteredData,
        isItemLoading: false,
        noItemData: false,
      });
    });
  };

  findItemQty = (event) => {
    var data = this.state.itemdata;
    this.setState({ itemqty: event.target.value.toLowerCase() }, () => {
      if (this.state.itemValue !== "") {
        const newdata = this.state.itemdata.filter(
          ({ Description, No, Buy_from_Vendor_Name }) => {
            Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
            Description = Description.toLowerCase();
            No = No.toLowerCase();
            var currValuelower = this.state.itemValue.toLowerCase();
            return (
              Buy_from_Vendor_Name.includes(currValuelower) ||
              Description.includes(currValuelower) ||
              No.includes(currValuelower)
            );
          }
        );

        data = newdata;
      }
      const filteredData = data.filter(
        ({
          PostingDescription,
          Buy_from_Vendor_Name,
          FormatType,
          Description,
          Dim1Code,
          No,
          Quantity,
          Quantity_Received,
          QtyOutstanding,
          Unit_of_Measure,
        }) => {
          FormatType = FormatType.toLowerCase();
          Description = Description.toLowerCase();
          Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
          No = No.toLowerCase();
          PostingDescription = PostingDescription.toLowerCase();
          Unit_of_Measure = Unit_of_Measure.toLowerCase();
          Dim1Code = Dim1Code.toLowerCase();
          return (
            PostingDescription.toString().includes(this.state.itemdesc) &&
            Buy_from_Vendor_Name.includes(this.state.itemvendor) &&
            No.includes(this.state.itempart) &&
            Description.includes(this.state.itempartdesc) &&
            FormatType.includes(this.state.itemtype) &&
            Dim1Code.includes(this.state.itemdiv) &&
            Quantity.toString().includes(this.state.itemqty) &&
            Quantity_Received.toString().includes(this.state.itemqtyrcd) &&
            QtyOutstanding.toString().includes(this.state.itemqtyrem) &&
            Unit_of_Measure.includes(this.state.itemunit)
          );
        }
      );
      if (event.target.value === "") {
        document.getElementById(
          "itemqty"
        ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
          "";
        document.getElementById(
          "itemqtyInput"
        ).parentNode.parentNode.style.backgroundColor = "";
      } else {
        document.getElementById(
          "itemqty"
        ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
          "lightblue";
        document.getElementById(
          "itemqtyInput"
        ).parentNode.parentNode.style.backgroundColor = "lightblue";
      }
      this.setState({
        itemdataSource: filteredData,
        isItemLoading: false,
        noItemData: false,
      });
    });
  };

  findItemQtyRcd = (event) => {
    var data = this.state.itemdata;
    this.setState({ itemqtyrcd: event.target.value.toLowerCase() }, () => {
      if (this.state.itemValue !== "") {
        const newdata = this.state.itemdata.filter(
          ({ Description, No, Buy_from_Vendor_Name }) => {
            Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
            Description = Description.toLowerCase();
            No = No.toLowerCase();
            var currValuelower = this.state.itemValue.toLowerCase();
            return (
              Buy_from_Vendor_Name.includes(currValuelower) ||
              Description.includes(currValuelower) ||
              No.includes(currValuelower)
            );
          }
        );

        data = newdata;
      }
      const filteredData = data.filter(
        ({
          PostingDescription,
          Buy_from_Vendor_Name,
          FormatType,
          Description,
          Dim1Code,
          No,
          Quantity,
          Quantity_Received,
          QtyOutstanding,
          Unit_of_Measure,
        }) => {
          FormatType = FormatType.toLowerCase();
          Description = Description.toLowerCase();
          Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
          No = No.toLowerCase();
          PostingDescription = PostingDescription.toLowerCase();
          Unit_of_Measure = Unit_of_Measure.toLowerCase();
          Dim1Code = Dim1Code.toLowerCase();
          return (
            PostingDescription.toString().includes(this.state.itemdesc) &&
            Buy_from_Vendor_Name.includes(this.state.itemvendor) &&
            No.includes(this.state.itempart) &&
            Description.includes(this.state.itempartdesc) &&
            FormatType.includes(this.state.itemtype) &&
            Dim1Code.includes(this.state.itemdiv) &&
            Quantity.toString().includes(this.state.itemqty) &&
            Quantity_Received.toString().includes(this.state.itemqtyrcd) &&
            QtyOutstanding.toString().includes(this.state.itemqtyrem) &&
            Unit_of_Measure.includes(this.state.itemunit)
          );
        }
      );
      if (event.target.value === "") {
        document.getElementById(
          "itemqtyrcd"
        ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
          "";
        document.getElementById(
          "itemqtyrcdInput"
        ).parentNode.parentNode.style.backgroundColor = "";
      } else {
        document.getElementById(
          "itemqtyrcd"
        ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
          "lightblue";
        document.getElementById(
          "itemqtyrcdInput"
        ).parentNode.parentNode.style.backgroundColor = "lightblue";
      }
      this.setState({
        itemdataSource: filteredData,
        isItemLoading: false,
        noItemData: false,
      });
    });
  };

  findItemQtyRem = (event) => {
    var data = this.state.itemdata;
    this.setState({ itemqtyrem: event.target.value.toLowerCase() }, () => {
      if (this.state.itemValue !== "") {
        const newdata = this.state.itemdata.filter(
          ({ Description, No, Buy_from_Vendor_Name }) => {
            Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
            Description = Description.toLowerCase();
            No = No.toLowerCase();
            var currValuelower = this.state.itemValue.toLowerCase();
            return (
              Buy_from_Vendor_Name.includes(currValuelower) ||
              Description.includes(currValuelower) ||
              No.includes(currValuelower)
            );
          }
        );

        data = newdata;
      }
      const filteredData = data.filter(
        ({
          PostingDescription,
          Buy_from_Vendor_Name,
          FormatType,
          Description,
          Dim1Code,
          No,
          Quantity,
          Quantity_Received,
          QtyOutstanding,
          Unit_of_Measure,
        }) => {
          FormatType = FormatType.toLowerCase();
          Description = Description.toLowerCase();
          Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
          No = No.toLowerCase();
          PostingDescription = PostingDescription.toLowerCase();
          Unit_of_Measure = Unit_of_Measure.toLowerCase();
          Dim1Code = Dim1Code.toLowerCase();
          return (
            PostingDescription.toString().includes(this.state.itemdesc) &&
            Buy_from_Vendor_Name.includes(this.state.itemvendor) &&
            No.includes(this.state.itempart) &&
            Description.includes(this.state.itempartdesc) &&
            FormatType.includes(this.state.itemtype) &&
            Dim1Code.includes(this.state.itemdiv) &&
            Quantity.toString().includes(this.state.itemqty) &&
            Quantity_Received.toString().includes(this.state.itemqtyrcd) &&
            QtyOutstanding.toString().includes(this.state.itemqtyrem) &&
            Unit_of_Measure.includes(this.state.itemunit)
          );
        }
      );
      if (event.target.value === "") {
        document.getElementById(
          "itemqtyrem"
        ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
          "";
        document.getElementById(
          "itemqtyremInput"
        ).parentNode.parentNode.style.backgroundColor = "";
      } else {
        document.getElementById(
          "itemqtyrem"
        ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
          "lightblue";
        document.getElementById(
          "itemqtyremInput"
        ).parentNode.parentNode.style.backgroundColor = "lightblue";
      }
      this.setState({
        itemdataSource: filteredData,
        isItemLoading: false,
        noItemData: false,
      });
    });
  };

  findItemUnit = (event) => {
    var data = this.state.itemdata;
    this.setState({ itemunit: event.target.value.toLowerCase() }, () => {
      if (this.state.itemValue !== "") {
        const newdata = this.state.itemdata.filter(
          ({ Description, No, Buy_from_Vendor_Name }) => {
            Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
            Description = Description.toLowerCase();
            No = No.toLowerCase();
            var currValuelower = this.state.itemValue.toLowerCase();
            return (
              Buy_from_Vendor_Name.includes(currValuelower) ||
              Description.includes(currValuelower) ||
              No.includes(currValuelower)
            );
          }
        );

        data = newdata;
      }
      const filteredData = data.filter(
        ({
          PostingDescription,
          Buy_from_Vendor_Name,
          FormatType,
          Description,
          Dim1Code,
          No,
          Quantity,
          Quantity_Received,
          QtyOutstanding,
          Unit_of_Measure,
        }) => {
          FormatType = FormatType.toLowerCase();
          Description = Description.toLowerCase();
          Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
          No = No.toLowerCase();
          PostingDescription = PostingDescription.toLowerCase();
          Unit_of_Measure = Unit_of_Measure.toLowerCase();
          Dim1Code = Dim1Code.toLowerCase();
          return (
            PostingDescription.toString().includes(this.state.itemdesc) &&
            Buy_from_Vendor_Name.includes(this.state.itemvendor) &&
            No.includes(this.state.itempart) &&
            Description.includes(this.state.itempartdesc) &&
            FormatType.includes(this.state.itemtype) &&
            Dim1Code.includes(this.state.itemdiv) &&
            Quantity.toString().includes(this.state.itemqty) &&
            Quantity_Received.toString().includes(this.state.itemqtyrcd) &&
            QtyOutstanding.toString().includes(this.state.itemqtyrem) &&
            Unit_of_Measure.includes(this.state.itemunit)
          );
        }
      );
      if (event.target.value === "") {
        document.getElementById(
          "itemunit"
        ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
          "";
        document.getElementById(
          "itemunitInput"
        ).parentNode.parentNode.style.backgroundColor = "";
      } else {
        document.getElementById(
          "itemunit"
        ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
          "lightblue";
        document.getElementById(
          "itemunitInput"
        ).parentNode.parentNode.style.backgroundColor = "lightblue";
      }
      this.setState({
        itemdataSource: filteredData,
        isItemLoading: false,
        noItemData: false,
      });
    });
  };

  findNo = (event) => {
    var data = this.state.POdata;
    if (this.state.value !== "") {
      const newdata = data.filter(
        ({
          No,
          Posting_Description,
          Buy_from_Vendor_Name,
          Dim1Code,
          Status,
        }) => {
          No = No.toLowerCase();
          Posting_Description = Posting_Description.toLowerCase();
          Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
          Dim1Code = Dim1Code.toLowerCase();
          Status = Status.toLowerCase();
          return (
            No.includes(this.state.value) ||
            Posting_Description.includes(this.state.value) ||
            Buy_from_Vendor_Name.includes(this.state.value) ||
            Dim1Code.includes(this.state.value) ||
            Status.includes(this.state.value)
          );
        }
      );
      data = newdata;
    }
    this.setState(
      { Nofiltered: event.target.value.toString().toLowerCase() },
      () => {
        const filteredData = data.filter(
          ({
            No,
            Posting_Description,
            Buy_from_Vendor_Name,
            Dim1Code,
            Status,
          }) => {
            No = No.toLowerCase();
            Posting_Description = Posting_Description.toLowerCase();
            Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
            Dim1Code = Dim1Code.toLowerCase();
            Status = Status.toLowerCase();
            return (
              No.includes(this.state.Nofiltered) &&
              Posting_Description.includes(this.state.Descfiltered) &&
              Buy_from_Vendor_Name.includes(this.state.Vendorfiltered) &&
              Dim1Code.includes(this.state.Divfiltered) &&
              Status.includes(this.state.Statusfiltered)
            );
          }
        );
        if (event.target.value === "") {
          document.getElementById(
            "pono"
          ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
            "";
          document.getElementById(
            "ponoInput"
          ).parentNode.parentNode.style.backgroundColor = "";
        } else {
          document.getElementById(
            "pono"
          ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
            "lightblue";
          document.getElementById(
            "ponoInput"
          ).parentNode.parentNode.style.backgroundColor = "lightblue";
        }
        this.setState({
          POdataSource: filteredData,
          isloading: false,
          noPOdata: false,
          isdataLoading: false,
        });
      }
    );
  };

  findDesc = (event) => {
    var data = this.state.POdata;
    if (this.state.value !== "") {
      const newdata = data.filter(
        ({
          No,
          Posting_Description,
          Buy_from_Vendor_Name,
          Dim1Code,
          Status,
        }) => {
          No = No.toLowerCase();
          Posting_Description = Posting_Description.toLowerCase();
          Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
          Dim1Code = Dim1Code.toLowerCase();
          Status = Status.toLowerCase();
          return (
            No.includes(this.state.value) ||
            Posting_Description.includes(this.state.value) ||
            Buy_from_Vendor_Name.includes(this.state.value) ||
            Dim1Code.includes(this.state.value) ||
            Status.includes(this.state.value)
          );
        }
      );
      data = newdata;
    }
    this.setState(
      { Descfiltered: event.target.value.toString().toLowerCase() },
      () => {
        const filteredData = data.filter(
          ({
            No,
            Posting_Description,
            Buy_from_Vendor_Name,
            Dim1Code,
            Status,
          }) => {
            No = No.toLowerCase();
            Posting_Description = Posting_Description.toLowerCase();
            Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
            Dim1Code = Dim1Code.toLowerCase();
            Status = Status.toLowerCase();
            return (
              No.includes(this.state.Nofiltered) &&
              Posting_Description.includes(this.state.Descfiltered) &&
              Buy_from_Vendor_Name.includes(this.state.Vendorfiltered) &&
              Dim1Code.includes(this.state.Divfiltered) &&
              Status.includes(this.state.Statusfiltered)
            );
          }
        );
        if (event.target.value === "") {
          document.getElementById(
            "podesc"
          ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
            "";
          document.getElementById(
            "podescInput"
          ).parentNode.parentNode.style.backgroundColor = "";
        } else {
          document.getElementById(
            "podesc"
          ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
            "lightblue";
          document.getElementById(
            "podescInput"
          ).parentNode.parentNode.style.backgroundColor = "lightblue";
        }
        this.setState({
          POdataSource: filteredData,
          isloading: false,
          noPOdata: false,
          isdataLoading: false,
        });
      }
    );
  };

  findVendor = (event) => {
    var data = this.state.POdata;
    if (this.state.value !== "") {
      const newdata = data.filter(
        ({
          No,
          Posting_Description,
          Buy_from_Vendor_Name,
          Dim1Code,
          Status,
        }) => {
          No = No.toLowerCase();
          Posting_Description = Posting_Description.toLowerCase();
          Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
          Dim1Code = Dim1Code.toLowerCase();
          Status = Status.toLowerCase();
          return (
            No.includes(this.state.value) ||
            Posting_Description.includes(this.state.value) ||
            Buy_from_Vendor_Name.includes(this.state.value) ||
            Dim1Code.includes(this.state.value) ||
            Status.includes(this.state.value)
          );
        }
      );
      data = newdata;
    }
    this.setState(
      { Vendorfiltered: event.target.value.toString().toLowerCase() },
      () => {
        const filteredData = data.filter(
          ({
            No,
            Posting_Description,
            Buy_from_Vendor_Name,
            Dim1Code,
            Status,
          }) => {
            No = No.toLowerCase();
            Posting_Description = Posting_Description.toLowerCase();
            Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
            Dim1Code = Dim1Code.toLowerCase();
            Status = Status.toLowerCase();
            return (
              No.includes(this.state.Nofiltered) &&
              Posting_Description.includes(this.state.Descfiltered) &&
              Buy_from_Vendor_Name.includes(this.state.Vendorfiltered) &&
              Dim1Code.includes(this.state.Divfiltered) &&
              Status.includes(this.state.Statusfiltered)
            );
          }
        );
        if (event.target.value === "") {
          document.getElementById(
            "povendor"
          ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
            "";
          document.getElementById(
            "povendorInput"
          ).parentNode.parentNode.style.backgroundColor = "";
        } else {
          document.getElementById(
            "povendor"
          ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
            "lightblue";
          document.getElementById(
            "povendorInput"
          ).parentNode.parentNode.style.backgroundColor = "lightblue";
        }
        this.setState({
          POdataSource: filteredData,
          isloading: false,
          noPOdata: false,
          isdataLoading: false,
        });
      }
    );
  };

  findDivision = (event) => {
    var data = this.state.POdata;
    if (this.state.value !== "") {
      const newdata = data.filter(
        ({
          No,
          Posting_Description,
          Buy_from_Vendor_Name,
          Dim1Code,
          Status,
        }) => {
          No = No.toLowerCase();
          Posting_Description = Posting_Description.toLowerCase();
          Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
          Dim1Code = Dim1Code.toLowerCase();
          Status = Status.toLowerCase();
          return (
            No.includes(this.state.value) ||
            Posting_Description.includes(this.state.value) ||
            Buy_from_Vendor_Name.includes(this.state.value) ||
            Dim1Code.includes(this.state.value) ||
            Status.includes(this.state.value)
          );
        }
      );
      data = newdata;
    }
    this.setState(
      { Divfiltered: event.target.value.toString().toLowerCase() },
      () => {
        const filteredData = data.filter(
          ({
            No,
            Posting_Description,
            Buy_from_Vendor_Name,
            Dim1Code,
            Status,
          }) => {
            No = No.toLowerCase();
            Posting_Description = Posting_Description.toLowerCase();
            Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
            Dim1Code = Dim1Code.toLowerCase();
            Status = Status.toLowerCase();
            return (
              No.includes(this.state.Nofiltered) &&
              Posting_Description.includes(this.state.Descfiltered) &&
              Buy_from_Vendor_Name.includes(this.state.Vendorfiltered) &&
              Dim1Code.includes(this.state.Divfiltered) &&
              Status.includes(this.state.Statusfiltered)
            );
          }
        );
        if (event.target.value === "") {
          document.getElementById(
            "podiv"
          ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
            "";
          document.getElementById(
            "podivInput"
          ).parentNode.parentNode.style.backgroundColor = "";
        } else {
          document.getElementById(
            "podiv"
          ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
            "lightblue";
          document.getElementById(
            "podivInput"
          ).parentNode.parentNode.style.backgroundColor = "lightblue";
        }
        this.setState({
          POdataSource: filteredData,
          isloading: false,
          noPOdata: false,
          isdataLoading: false,
        });
      }
    );
  };

  findStatus = (event) => {
    var data = this.state.POdata;
    if (this.state.value !== "") {
      const newdata = data.filter(
        ({
          No,
          Posting_Description,
          Buy_from_Vendor_Name,
          Dim1Code,
          Status,
        }) => {
          No = No.toLowerCase();
          Posting_Description = Posting_Description.toLowerCase();
          Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
          Dim1Code = Dim1Code.toLowerCase();
          Status = Status.toLowerCase();
          return (
            No.includes(this.state.value) ||
            Posting_Description.includes(this.state.value) ||
            Buy_from_Vendor_Name.includes(this.state.value) ||
            Dim1Code.includes(this.state.value) ||
            Status.includes(this.state.value)
          );
        }
      );
      data = newdata;
    }
    this.setState(
      { Statusfiltered: event.target.value.toString().toLowerCase() },
      () => {
        const filteredData = data.filter(
          ({
            No,
            Posting_Description,
            Buy_from_Vendor_Name,
            Dim1Code,
            Status,
          }) => {
            No = No.toLowerCase();
            Posting_Description = Posting_Description.toLowerCase();
            Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
            Dim1Code = Dim1Code.toLowerCase();
            Status = Status.toLowerCase();
            return (
              No.includes(this.state.Nofiltered) &&
              Posting_Description.includes(this.state.Descfiltered) &&
              Buy_from_Vendor_Name.includes(this.state.Vendorfiltered) &&
              Dim1Code.includes(this.state.Divfiltered) &&
              Status.includes(this.state.Statusfiltered)
            );
          }
        );
        if (event.target.value === "") {
          document.getElementById(
            "postatus"
          ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
            "";
          document.getElementById(
            "postatusInput"
          ).parentNode.parentNode.style.backgroundColor = "";
        } else {
          document.getElementById(
            "postatus"
          ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
            "lightblue";
          document.getElementById(
            "postatusInput"
          ).parentNode.parentNode.style.backgroundColor = "lightblue";
        }
        this.setState({
          POdataSource: filteredData,
          isloading: false,
          noPOdata: false,
          isdataLoading: false,
        });
      }
    );
  };

  subReload = () => {
    this.setState({
      isSubloading: true,
      subdataSource: [],
      subdata: [],
      noPOsubdata:false
    });
    const PL = {
      poNumber: this.state.PORow.No,
      Division:this.props.division
    };
    axios
      .post(`${address}/nav/subdata`, PL, {
        headers: {
          Authorization: localStorage.getItem("beartoken"),
        },
      })
      .then((res) => {
        if (res.data === "No") {
          this.setState({
            isSubloading: false,
            noPOsubdata: true,
            subdataSource: [],
            subdata: [],
          });
        } else {
          const filteredData = res.data.filter(
            ({
              Line_No,
              FormatType,
              Description,
              No,
              PartDescription,
              Quantity,
              Quantity_Received,
              QtyOutstanding,
              Unit_of_Measure,
            }) => {
              FormatType = FormatType.toLowerCase();
              Description = Description.toLowerCase();
              No = No.toLowerCase();
              PartDescription = PartDescription.toLowerCase();
              Unit_of_Measure = Unit_of_Measure.toLowerCase();
              var currValuelower = this.state.subValue.toLowerCase();
              return (
                Line_No.toString().includes(currValuelower) ||
                FormatType.includes(currValuelower) ||
                Description.includes(currValuelower) ||
                No.includes(currValuelower) ||
                PartDescription.includes(currValuelower) ||
                Quantity.toString().includes(currValuelower) ||
                Quantity_Received.toString().includes(currValuelower) ||
                QtyOutstanding.toString().includes(currValuelower) ||
                Unit_of_Measure.includes(currValuelower)
              );
            }
          );

          this.setState({
            subdataSource: filteredData,
            subdata: res.data,
            isSubloading: false,
           // noPOsubdata: false,
            noPOsubdata: false,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("EReceiptuser");
          store.dispatch({
            type: DISCONNECT,
          });
          this.props.history.push("/login");
        }
      });
  };

  handleOpenModalEmail = () => {
    console.log(this.props.division);
    this.setState({ showModalMessage: false,emailSubject:"" },()=>{
      this.onReset();
    });
    this.state.emailSubject="";
    const emailbody =
      "User Name : " +
      this.props.user.fullName +
      "\r" +
      "PO Number : " +
      this.state.partInfo.Document_No +
      "\r" +
      "PO Line Number : " +
      this.state.partInfo.Line_No +
      "\r" +
      "Part Number : " +
      this.state.partInfo.No +
      "\r" +
      "Quantity : " +
      this.state.partInfo.Quantity +
      "\r" +
      "New Quantity to Receive : " +
      this.state.storedQuantity +
      "\r" +
      "Error : " +
      this.state.msg +
      "\r\r\r" +
      "Thank You" +
      "\r" +
      this.props.user.fullName;
      console.log(this.props.division=='HAVEN');
    const sub =
      "ERROR: "+(this.props.division+"-receipt: ")+
      this.state.partInfo.Document_No +
      ", " +
      this.state.partInfo.No +
      ", Receive failed";
      console.log(sub);
      this.state.emailSubject= sub;
    this.setState({
      emailSubject: sub,
      emailSentSuccessful: 0,
      emailBody: emailbody,      
      showModalEmail: true,
      emailSent: 0,
    },()=>{
      console.log(sub);
    });
  };

  handleCloseModalEmail = () => {
    this.formRef.current.resetFields();
    this.setState({ showModalEmail: false,emailSubject:"" },()=>{
      this.onReset();
    });
  };

  handleQtyChange = (event) => {
    this.setState({ status: 0, emailSent: 0, newQuantity: event.target.value });
  };

  handleLotNoChange = (event) => {
    this.setState({ status: 0, emailSent: 0, lotNo: event.target.value });
  };

  // handleLocationChange = (event) => {
  //   this.setState({ status: 0, emailSent: 0, location: event.target.value });
  // };

  sendEmail = (values) => {
    this.setState({ isSending: true, emailSent: 0 }, () => {
      axios
        .post(`${address}/nav/sendEmail`, values, {
          headers: {
            Authorization: localStorage.getItem("beartoken"),
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            this.setState({
              isSending: false,
              emailSent: 1,
              emailSentSuccessful: 0,
            });
            this.formRef.current.resetFields();
            this.handleCloseModalEmail();
          } else {
            this.setState({
              isSending: false,
              emailSent: 1,
              emailSentSuccessful: 1,
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.removeItem("EReceiptuser");
            store.dispatch({
              type: DISCONNECT,
            });
            this.props.history.push("/login");
          }
        });
    });
  };

  handleUpdate = async () => {
    let row = this.state.partInfo;
    this.setState({
      msg: "Please Wait..",
      storedQuantity: this.state.newQuantity ? this.state.newQuantity : 0,
    });

    var rate = 0;
    var today = new Date();
    //if all good
    const POL = {
      poNumber: row.Document_No,
      partNumber: this.state.partInfo.No,
      plNumber: row.Line_No,
      pltype: row.Document_Type,
      newQty: this.state.newQuantity ? this.state.newQuantity : 0,
      Qty: row.Quantity,
      machineTime: this.state.machiningTime,
      grossWt: this.state.grossWt,
      rate: rate,
      WorkCenterGroup: this.state.partInfo.WorkCenterGroup,
      Qty_Out: row.QtyOutstanding,
      userInfo: this.props.user ? this.props.user.fullName : "",
      lotNo: this.state.lotNo,
      location: this.state.location,
      newDate:
        this.state.date.year() +
        "-" +
        (this.state.date.month() + 1) +
        "-" +
        this.state.date.date(),
      newTime:
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds(),
    };
    const PL = {
      poNumber: row.Document_No,
      Division:this.props.division
    };
    this.state.updatedPO = row.Document_No;

    //Update the Qty_to_Receive in NAV
    axios
      .post(`${address}/nav/update`, POL, {
        headers: {
          Authorization: localStorage.getItem("beartoken"),
        },
      })
      .then((res) => {
        this.setState({
          msg: res.data.msg,
          msg_type: res.data.type,
          status: 2,
          isposting: false,
        });
        if (this.state.status === 2 && this.state.msg_type === false) {
          this.handleOpenModalMessage();
        } else if (this.state.status === 2 && this.state.msg_type === true) {
          console.log(this.state.partInfo.QtyOutstanding,this.state.newQuantity);
          if(this.state.partInfo.QtyOutstanding<=Number(this.state.newQuantity)){
            this.state.IsPOLineFullyRcd=true;
          }else{
            this.state.IsPOLineFullyRcd=false;
          }
          console.log("IsPOLineFullyRcd",this.state.IsPOLineFullyRcd);
          var today = new Date();
          const todayDate =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getDate();
          const todayTime =
            today.getHours() +
            ":" +
            today.getMinutes() +
            ":" +
            today.getSeconds();
          var element = {
            ReceiptId:
              this.state.partInfo.PrimaryKeyCombo +
              todayDate +
              todayTime +
              this.state.newQuantity,
            PONo: this.state.partInfo.Document_No,
            MO: this.state.partInfo.PostingDescription,
            Line_No: this.state.partInfo.Line_No,
            PartNo: this.state.partInfo.No,
            PODesc: this.state.partInfo.Description,
            PartDesc: this.state.partInfo.PartDescription,
            WorkCenterGroup: this.state.partInfo.WorkCenterGroup,
            Qty:
              Math.round(
                (parseFloat(this.state.newQuantity) + Number.EPSILON) * 100
              ) / 100,
            UserId: this.props.user ? this.props.user.fullName : "",
            PostDate: todayDate,
            PostTime: todayTime,
          };
          // axios.post(`${address}/nav/addPackInfo`, element);
        }

        //updates the PO Lines

        if (this.state.isItem === false) {
          this.setState({ isSubloading: true, subchanged: true });
          //if(this.state.PL_Count)
          axios
            .post(`${address}/nav/subdata`, PL, {
              headers: {
                Authorization: localStorage.getItem("beartoken"),
              },
            })
            .then((res) => {
              if (res.data === "No") {
                this.setState({noPOsubdata: true, subdata: [],noPOsubdata: true,isSubloading:false,subdataSource:[] },()=>{                 
                  if(this.state.showPostModal){
                    this.handleClosePostModal();
                  }
                });
              } else {
                const filteredData = res.data.filter(
                  ({
                    Line_No,
                    FormatType,
                    Description,
                    No,
                    PartDescription,
                    Quantity,
                    Quantity_Received,
                    QtyOutstanding,
                    Unit_of_Measure,
                  }) => {
                    FormatType = FormatType.toLowerCase();
                    Description = Description.toLowerCase();
                    No = No.toLowerCase();
                    PartDescription = PartDescription.toLowerCase();
                    Unit_of_Measure = Unit_of_Measure.toLowerCase();
                    var currValuelower = this.state.subValue.toLowerCase();
                    console.log(currValuelower);
                    return (
                      Line_No.toString().includes(currValuelower) ||
                      FormatType.includes(currValuelower) ||
                      Description.includes(currValuelower) ||
                      No.includes(currValuelower) ||
                      PartDescription.includes(currValuelower) ||
                      Quantity.toString().includes(currValuelower) ||
                      Quantity_Received.toString().includes(currValuelower) ||
                      QtyOutstanding.toString().includes(currValuelower) ||
                      Unit_of_Measure.includes(currValuelower)
                    );
                  }
                );
                console.log(filteredData);
                console.log(res.data);
                this.setState({
                  subdataSource: filteredData,
                  subdata: res.data,
                  isSubloading: false,
                  newQuantity: "",
                  subchanged: false,
                  j: 0,
                  noPOsubdata:false,
                //  noPOsubdata: false,
                  subcolumnchanged: false,
                  //lotNo: '',
                  locationMandatory: false,
                });
                const PLL = {
                  poNumber: filteredData[0].Document_No,
                  plNumber: filteredData[0].Line_No,
                  pltype: filteredData[0].Document_Type,
                };
                console.log(PLL);
                if(!this.state.IsPOLineFullyRcd){
                axios
                  .post(`${address}/nav/subpart`, PLL, {
                    headers: {
                      Authorization: localStorage.getItem("beartoken"),
                    },
                  })
                  .then((res) => {
                    this.setState({
                      partInfo: res.data,
                      newQuantity: "",
                      grossWt: res.data.Gross_Weight,
                      machineTime: res.data.MachiningTime,
                    });
                  })
                  .catch((error) => {
                    if (error.response.status === 401) {
                      localStorage.removeItem("EReceiptuser");
                      store.dispatch({
                        type: DISCONNECT,
                      });
                      this.props.history.push("/login");
                    }
                  });
                }else{
                  this.handleClosePostModal();
                }
                this.state.isposting = false;
                //updates the PL line whose Qty_to_Receive has changed.
              }
            })
            .catch((error) => {
              if (error.response.status === 401) {
                localStorage.removeItem("EReceiptuser");
                store.dispatch({
                  type: DISCONNECT,
                });
                this.props.history.push("/login");
              }
            });
        } else {
          const PLL = {
            poNumber: this.state.partInfo.Document_No,
            plNumber: this.state.partInfo.Line_No,
            pltype: this.state.partInfo.Document_Type,
          };
          
          this.setState({ itemdata: [], isItemLoading: true },()=>{
            if(!this.state.IsPOLineFullyRcd){
            axios
                  .post(`${address}/nav/subpart`, PLL, {
                    headers: {
                      Authorization: localStorage.getItem("beartoken"),
                    },
                  })
                  .then((res) => {
                    this.setState({
                      partInfo: res.data,
                      newQuantity: "",
                      grossWt: res.data.Gross_Weight,
                      machineTime: res.data.MachiningTime,
                    });
                  })
                }else{
                  this.handleClosePostModal();
                }
          });
        
          this.searchItems(this.state.itemValue);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("EReceiptuser");
          store.dispatch({
            type: DISCONNECT,
          });
          this.props.history.push("/login");
        }
      });
  };

  handlePost = () => {
    this.setState({
      msg_type: false,
      status: 1,
      submsg: "",
      msg: "",
      isposting: true,
      emailSent: 0,
    });
    if (this.state.machiningTime === "" || this.state.machiningTime === " ") {
      this.setState({ machineTime: 0 });
    }
    if (this.state.grossWt === "") {
      this.setState({ grossWt: 0 });
    }
    //error messages
    if (!this.state.isItem) {
      // if (
      //   ((this.state.machiningTime <= 0 &&
      //     this.state.PORow.Buy_from_Vendor_Name === "STEELBOSS") ||
      //     this.state.PORow.Buy_from_Vendor_Name === "steelboss") &&
      //   this.state.partInfo.WorkCenterGroup !== ""
      // ) {
      //   this.setState({
      //     submsg: "Machining Time should be greater than 0.",
      //     isposting: false,
      //     status: 0,
      //   });
      // } else if (
      //   this.state.grossWt <= 0 &&
      //   (this.state.PORow.Buy_from_Vendor_Name.includes("PERSONA") ||
      //     this.state.PORow.Buy_from_Vendor_Name.includes("persona")) &&
      //   this.state.partInfo.WorkCenterGroup !== ""
      // ) {
      //   this.setState({
      //     submsg: "Gross Weight should be greater than 0.",
      //     isposting: false,
      //     status: 0,
      //   });
      // } else 
      if (isNaN(this.state.newQuantity)) {
        this.setState({
          submsg: "Quantity to Receive should be a Numeric Value.",
          isposting: false,
          status: 0,
        });
      } else if (Math.sign(this.state.newQuantity) < 0) {
        this.setState({
          submsg: "Quantity to Receive should be positive Number",
          isposting: false,
          status: 0,
        });
      } else if (this.state.lotNo === "" && this.state.lotNoMandatory) {
        this.setState({
          submsg: "Lot number must not be empty ",
          isposting: false,
          status: 0,
        });
      } else if (this.state.location === "") {
        this.setState({
          submsg: "Location must not be empty ",
          isposting: false,
          status: 0,
        });
      } else {
        this.handleUpdate();
      }
    } else if (this.state.isItem) {
      // if (
      //   this.state.machiningTime <= 0 &&
      //   (this.state.partInfo.Buy_from_Vendor_Name === "STEELBOSS" ||
      //     this.state.partInfo.Buy_from_Vendor_Name === "steelboss") &&
      //   this.state.partInfo.WorkCenterGroup !== ""
      // ) {
      //   this.setState({
      //     submsg: "Machining Time should be greater than 0.",
      //     isposting: false,
      //     status: 0,
      //   });
      // } else if (
      //   this.state.grossWt <= 0 &&
      //   (this.state.partInfo.Buy_from_Vendor_Name.includes("PERSONA") ||
      //     this.state.partInfo.Buy_from_Vendor_Name.includes("persona")) &&
      //   this.state.partInfo.WorkCenterGroup !== ""
      // ) {
      //   this.setState({
      //     submsg: "Gross Weight should be greater than 0.",
      //     isposting: false,
      //     status: 0,
      //   });
      // } else
       if (isNaN(this.state.newQuantity)) {
        this.setState({
          submsg: "Quantity to Receive should be a Numeric Value.",
          isposting: false,
          status: 0,
        });
      } else if (Math.sign(this.state.newQuantity) < 0) {
        this.setState({
          submsg: "Quantity to Receive should be positive Number",
          isposting: false,
          status: 0,
        });
      } else if (this.state.lotNo === "" && this.state.lotNoMandatory) {
        this.setState({
          submsg: "Lot number must not be empty ",
          isposting: false,
          status: 0,
        });
      } else if (this.state.location === "") {
        this.setState({
          submsg: "Location must not be empty ",
          isposting: false,
          status: 0,
        });
      } else {
        this.handleUpdate();
      }
    }
  };

  handleData = () => {
    if (document.getElementById("Dim1Code") !== null) {
      document.getElementById("Dim1Code").placeholder = "Branch";
      document.getElementById("Dim1Code").disabled = true;
      const searchData = this.state.POdata.filter(
        ({
          No,
          Posting_Description,
          Buy_from_Vendor_Name,
          Dim1Code,
          Status,
        }) => {
          No = No.toLowerCase();
          Posting_Description = Posting_Description.toLowerCase();
          Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
          Dim1Code = Dim1Code.toLowerCase();
          Status = Status.toLowerCase();
          var currValuelower = this.state.value.toLowerCase();
          return (
            No.includes(currValuelower) ||
            Posting_Description.includes(currValuelower) ||
            Buy_from_Vendor_Name.includes(currValuelower) ||
            Dim1Code.includes(currValuelower) ||
            Status.includes(currValuelower)
          );
        }
      );
      this.setState({ POdataSource: searchData, filterdata: searchData });
      if (this.state.paramList.hasOwnProperty("No")) {
        const filteredData = this.state.filterdata.filter(({ No }) => {
          No = No.toLowerCase();
          return (
            No.includes(this.state.paramList.No) ||
            No.includes(this.state.paramList.No.toLowerCase())
          );
        });
        this.setState({ filterdata: filteredData });
      }
      if (this.state.paramList.hasOwnProperty("Posting_Description")) {
        const filteredData = this.state.filterdata.filter(
          ({ Posting_Description }) => {
            Posting_Description = Posting_Description.toLowerCase();
            let currvalue =
              this.state.paramList.Posting_Description.toLowerCase();
            return Posting_Description.includes(currvalue);
          }
        );
        this.setState({ filterdata: filteredData });
      }
      if (this.state.paramList.hasOwnProperty("Buy_from_Vendor_Name")) {
        const filteredData = this.state.filterdata.filter(
          ({ Buy_from_Vendor_Name }) => {
            Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
            return Buy_from_Vendor_Name.includes(
              this.state.paramList.Buy_from_Vendor_Name.toLowerCase()
            );
          }
        );
        this.setState({ filterdata: filteredData });
      }
      if (this.state.paramList.hasOwnProperty("Dim1Code")) {
        const filteredData = this.state.filterdata.filter(({ Dim1Code }) => {
          Dim1Code = Dim1Code.toLowerCase();
          return Dim1Code.includes(this.state.paramList.Dim1Code.toLowerCase());
        });
        this.setState({ filterdata: filteredData });
      }
      if (this.state.paramList.hasOwnProperty("Status")) {
        const filteredData = this.state.filterdata.filter(({ Status }) => {
          Status = Status.toLowerCase();
          return Status.includes(this.state.paramList.Status.toLowerCase());
        });
        this.setState({ filterdata: filteredData });
      }
      this.setState({ POdataSource: this.state.filterdata });

      return this.state.POdataSource;
    }
  };

  onDateChange = (date, dateString) => {
    console.log(this.state.date);
    console.log(date);
    console.log(dateString);
    this.setState({ date: date });
  };

  handleOpenSubModal = () => {
    this.setState({ showSubModal: true, subsearchValue: "" }, () => {});
  };

  handleCloseSubModal = () => {
    this.setState({ selectedPart: "",subValue:'', showSubModal: false });
  };

  handleClosItemModal = () => {
    this.setState({ showItemModal: false, selectedItemPart: "" });
  };

  handleChangeLoaction = (value) => {
    this.setState({ status: 0, emailSent: 0, location: value });
  };

  handleOpenPostModal = () => {
    this.setState({
      //lotNo: '',
      newQuantity: "",
      showPostModal: true,
      emailSentSuccessful: 0,
      emailSent: 0,
      IsPOLineFullyRcd: false
    });
  };

  handleClosePostModal = () => {
    if (this.state.showSubModal) {
      this.setState({
        showPostModal: false,
        grossWt: "",
        machiningTime: "",
        date: moment(),
        IsPOLineFullyRcd: false
      });
    } else {
      this.setState({
        showPostModal: false,
        grossWt: "",
        machiningTime: "",
        date: moment(),
        IsPOLineFullyRcd: false
      });
    }
  };

  handleOpenItemModal = () => {
    console.log(this.props.division);
    this.setState(
      {
        isItemLoading: false,
        itemdata: [],
        itemdataSource: [],
        noItemData: false,
        itemValue: "",
        selectedRow: "",
        selectedPart: "",
      },
      () => {
        this.setState({ showItemModal: true });
      }
    );
  };

  handleCloseItemModal = () => {
    this.setState(
      {
        selectedItem: "",
        selectedPO: "",
        isItemLoading: false,
        itemdata: [],
        itemdataSource: [],
        noItemData: false,
        itemValue: "",
        itemdesc: "",
        itemvendor: "",
        itemtype: "",
        itempart: "",
        itempartdesc: "",
        itemdiv: "",
        itemqty: "",
        itemqtyrcd: "",
        itemqtyrem: "",
        itemunit: "",
      },
      () => {
        document.getElementById(
          "itemdesc"
        ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
          "";
        document.getElementById(
          "itemdescInput"
        ).parentNode.parentNode.style.backgroundColor = "";
        document.getElementById(
          "itemvendor"
        ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
          "";
        document.getElementById(
          "itemvendorInput"
        ).parentNode.parentNode.style.backgroundColor = "";
        document.getElementById(
          "itempart"
        ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
          "";
        document.getElementById(
          "itempartInput"
        ).parentNode.parentNode.style.backgroundColor = "";
        document.getElementById(
          "itemtype"
        ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
          "";
        document.getElementById(
          "itemtypeInput"
        ).parentNode.parentNode.style.backgroundColor = "";
        document.getElementById(
          "itempartdesc"
        ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
          "";
        document.getElementById(
          "itempartdescInput"
        ).parentNode.parentNode.style.backgroundColor = "";
        document.getElementById(
          "itemqty"
        ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
          "";
        document.getElementById(
          "itemqtyInput"
        ).parentNode.parentNode.style.backgroundColor = "";
        document.getElementById(
          "itemqtyrcd"
        ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
          "";
        document.getElementById(
          "itemqtyrcdInput"
        ).parentNode.parentNode.style.backgroundColor = "";
        document.getElementById(
          "itemqtyrem"
        ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
          "";
        document.getElementById(
          "itemqtyremInput"
        ).parentNode.parentNode.style.backgroundColor = "";
        document.getElementById(
          "itemunit"
        ).parentNode.parentNode.parentNode.parentNode.style.backgroundColor =
          "";
        document.getElementById(
          "itemunitInput"
        ).parentNode.parentNode.style.backgroundColor = "";
        this.setState({ showItemModal: false });
      }
    );
  };

  searchValue = (e) => {
    const currValue = e.target.value;
    this.setState({ value: currValue, search: true });
    var data = this.state.POdata;
    const newdata = data.filter(
      ({ No, Posting_Description, Buy_from_Vendor_Name, Dim1Code, Status }) => {
        No = No.toLowerCase();
        Posting_Description = Posting_Description.toLowerCase();
        Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
        Dim1Code = Dim1Code.toLowerCase();
        Status = Status.toLowerCase();
        return (
          No.includes(this.state.Nofiltered) &&
          Posting_Description.includes(this.state.Descfiltered) &&
          Buy_from_Vendor_Name.includes(this.state.Vendorfiltered) &&
          Dim1Code.includes(this.state.Divfiltered) &&
          Status.includes(this.state.Statusfiltered)
        );
      }
    );
    data = newdata;
    const filteredData = data.filter(
      ({ No, Posting_Description, Buy_from_Vendor_Name, Dim1Code, Status }) => {
        No = No.toLowerCase();
        Posting_Description = Posting_Description.toLowerCase();
        Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
        Dim1Code = Dim1Code.toLowerCase();
        Status = Status.toLowerCase();
        var currValuelower = currValue.toLowerCase();
        return (
          No.includes(currValuelower) ||
          Posting_Description.includes(currValuelower) ||
          Buy_from_Vendor_Name.includes(currValuelower) ||
          Dim1Code.includes(currValuelower) ||
          Status.includes(currValuelower)
        );
      }
    );
    this.setState({ POdataSource: filteredData, search: false });
  };

  searchSubValue = (e) => {
    const currValue = e.target.value;
    this.setState({ subValue: currValue });
    const filteredData = this.state.subdata.filter(
      ({
        Line_No,
        FormatType,
        Description,
        No,
        PartDescription,
        Quantity,
        Quantity_Received,
        QtyOutstanding,
        Unit_of_Measure,
      }) => {
        FormatType = FormatType.toLowerCase();
        Description = Description.toLowerCase();
        No = No.toLowerCase();
        PartDescription = PartDescription.toLowerCase();
        Unit_of_Measure = Unit_of_Measure.toLowerCase();
        var currValuelower = currValue.toLowerCase();
        return (
          Line_No.toString().includes(currValuelower) ||
          FormatType.includes(currValuelower) ||
          Description.includes(currValuelower) ||
          No.includes(currValuelower) ||
          PartDescription.includes(currValuelower) ||
          Quantity.toString().includes(currValuelower) ||
          Quantity_Received.toString().includes(currValuelower) ||
          QtyOutstanding.toString().includes(currValuelower) ||
          Unit_of_Measure.includes(currValuelower)
        );
      }
    );
    this.setState({ subdataSource: filteredData });
  };

  searchItemValue = (value) => {
    if (value === "" || value === " ") {
      this.setState({
        itemValue: "",
        itemdata: [],
        itemdataSource: [],
        isItemLoading: false,
      });
    } else {
      const currValue = value;
      this.setState({ itemValue: currValue }, () => {});
      const data = this.state.itemdata.filter(
        ({ Description, No, Buy_from_Vendor_Name }) => {
          Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
          Description = Description.toLowerCase();
          No = No.toLowerCase();
          var currValuelower = currValue.toLowerCase();
          return (
            Buy_from_Vendor_Name.includes(currValuelower) ||
            Description.includes(currValuelower) ||
            No.includes(currValuelower)
          );
        }
      );
      console.log("itemDatafilter",data);

      const filteredData = data.filter(
        ({
          PostingDescription,
          Buy_from_Vendor_Name,
          FormatType,
          Description,
          Dim1Code,
          No,
          Quantity,
          Quantity_Received,
          QtyOutstanding,
          Unit_of_Measure,
        }) => {
          FormatType = FormatType.toLowerCase();
          Description = Description.toLowerCase();
          Buy_from_Vendor_Name = Buy_from_Vendor_Name.toLowerCase();
          No = No.toLowerCase();
          PostingDescription = PostingDescription.toLowerCase();
          Unit_of_Measure = Unit_of_Measure.toLowerCase();
          Dim1Code = Dim1Code.toLowerCase();
          return (
            PostingDescription.toString().includes(this.state.itemdesc) &&
            Buy_from_Vendor_Name.includes(this.state.itemvendor) &&
            No.includes(this.state.itempart) &&
            Description.includes(this.state.itempartdesc) &&
            FormatType.includes(this.state.itemtype) &&
            Dim1Code.includes(this.state.itemdiv) &&
            Quantity.toString().includes(this.state.itemqty) &&
            Quantity_Received.toString().includes(this.state.itemqtyrcd) &&
            QtyOutstanding.toString().includes(this.state.itemqtyrem) &&
            Unit_of_Measure.includes(this.state.itemunit)
          );
        }
      );
      console.log(filteredData);
      this.setState(
        { itemdataSource: filteredData, isItemLoading: false },
        () => {
          if (this.state.itemdataSource.length === 0) {
            this.setState({ noItemData: true });
          }
        }
      );
    }
  };

  changeItemValue = (e) => {
    const currValue = e.target.value;
    this.setState(
      {
        itemValue: currValue,
        itemdataSource: [],
        itemdata: [],
        noItemData: false,
      },
      () => {}
    );
  };

  searchItems = (value) => {
    if (value !== "" && value !== " ") {
      this.setState(
        {
          isItemLoading: true,
          itemdataSource: [],
          noItemData: false,
          itemdata: [],
        },
        () => {
          axios
            .post(
              `${address}/nav/itemAll`,
              {
                Value: value,
                Division:this.props.division
              },
              {
                headers: {
                  Authorization: localStorage.getItem("beartoken"),
                },
              }
            )
            .then((res) => {
              if (res.data === "No") {
                this.setState({
                  isItemLoading: false,
                  itemdataSource: [],
                  noItemData: true,
                });
              } else {
                this.setState({ itemdata: res.data });
                //this.state.itemdata = res.data;
                this.searchItemValue(this.state.itemValue);
              }
            })
            .catch((error) => {
              console.log(error);
              if (error.response.status === 401) {
                localStorage.removeItem("EReceiptuser");
                store.dispatch({
                  type: DISCONNECT,
                });
                this.props.history.push("/login");
              }
            });
        }
      );
    } else {
      this.setState({ isItemLoading: false });
    }
  };

  onClickRow = (row) => {
    return {
      onClick: () => {
        this.setState({
          selectedRow: "",
          PORow: "",
          selectedMO: "",
          selectedPart: "",
          partInfo: "",
          isItem: false,
          packingitemArray: [],
          isloading: true,
          noPOsubdata:false,
        });
        if (
          this.state.showSubModal === false &&
          this.state.showPostModal === false
        ) {
          this.setState(
            {
              selectedRow: row.No,
              POStatus: row.Status,
              selectedMO: row.Posting_Description,
              PORow: row,
            },
            () => {
              this.state.packingarray.forEach((item) => {
                if (item.Document_No === this.state.selectedRow) {
                  this.state.packingitemArray.push(item);
                }
              });
            }
          );
          this.setState({
            msg: "",
            submsg: "",
            msg_type: false,
            status: 0,
          });
          const PL = {
            poNumber: row.No,
            Division:this.props.division
          };

          // axios
          //   .get(`${address}/nav/getLocations`, {
          //     headers: {
          //       Authorization: localStorage.getItem("beartoken"),
          //     },
          //   })
          //   .then((res) => {
          //     this.setState({ allLocations: res.data });
          //   })
          //   .catch((error) => {
          //     if (error.response.status === 401) {
          //       localStorage.removeItem("EReceiptuser");
          //       store.dispatch({
          //         type: DISCONNECT,
          //       });
          //       this.props.history.push("/login");
          //     }
          //   });

          //get the count of the PL Lines in a PO Order
          axios
            .post(`${address}/nav/countValue`, PL, {
              headers: {
                Authorization: localStorage.getItem("beartoken"),
              },
            })
            .then((res) => {
              if (res.data === "No") {
                this.setState({
                  subdata: [],
                  noPOsubdata: true,
                  isloading: false,
                  isSubloading: false,
                });
                this.handleOpenSubModal();
              } else {
                this.setState({
                  PL_Count: res.data,
                });

                //if no PL Line for a PO Order
                if (this.state.PL_Count === 0) {
                  this.setState(
                    {
                      partInfo: row,
                      isloading: false,
                    },
                    () => {
                      let ModalTitle = (
                        <div className="title">
                          <span
                            className="modal-title"
                            style={{ color: "black" }}
                          >
                            <table width="100%">
                              <tbody>
                                <tr>
                                  <td width="20%">
                                    <b>PO #:</b>
                                  </td>
                                  <td width="80%">{this.state.partInfo.No}</td>
                                </tr>
                                <tr>
                                  <td width="20%">
                                    <b>MO #:</b>
                                  </td>
                                  <td width="80%">
                                    {this.state.partInfo.Posting_Description}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </span>
                        </div>
                      );
                      this.setState({ ModalTitle: ModalTitle }, () => {
                        this.handleOpenPostModal();
                      });
                    }
                  );
                } else {
                  axios
                    .post(`${address}/nav/subdata`, PL, {
                      headers: {
                        Authorization: localStorage.getItem("beartoken"),
                      },
                    })
                    .then((res) => {                   
                      if (res.data === "No") {
                        this.setState({
                          subdata: [],
                          subdataSource: [],
                          noPOsubdata: true,
                          showModalLoading: false,
                          isSubloading: false,
                        });
                        this.handleOpenSubModal();
                      } else {
                        if (this.state.PL_Count === 1) {
                          this.setState({
                            lotNo: res.data[0].Last_Heat_No,
                            partInfo: res.data[0],
                            isloading: false,
                            machiningTime: res.data[0].MachiningTime,
                            grossWt: res.data[0].Gross_Weight,
                            lotNoMandatory: res.data[0].LotNoMandatory,
                            location: res.data[0].Location_Code,
                            locationMandatory:
                              res.data[0].Location_Code === "" ? true : false,
                          });
                          let ModalTitle = (
                            <div className="title">
                              <span
                                className="modal-title"
                                style={{ color: "black" }}
                              >
                                <table width="100%">
                                  <tbody>
                                    <tr>
                                      <td width="20%">
                                        <b>Part #:</b>
                                      </td>
                                      <td width="80%">
                                        {this.state.partInfo.No}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="20%">
                                        <b>Desc:</b>
                                      </td>
                                      <td width="80%">
                                        {this.state.partInfo.PartDescription}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="20%">
                                        <b>PO-Desc:</b>
                                      </td>
                                      <td width="80%">
                                        {this.state.partInfo.Description}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </span>
                            </div>
                          );
                          this.setState({ ModalTitle: ModalTitle }, () => {
                            this.handleOpenPostModal();
                          });
                        } else {
                          this.setState({
                            subdata: res.data,
                            subdataSource: res.data,
                            isloading: false,
                            isSubloading: false,
                          });

                          this.handleOpenSubModal();
                        }
                      }
                    })
                    .catch((error) => {
                      if (error.response.status === 401) {
                        localStorage.removeItem("EReceiptuser");
                        store.dispatch({
                          type: DISCONNECT,
                        });
                        this.props.history.push("/login");
                      }
                    });
                }
              }
            })
            .catch((error) => {
              if (error.response.status === 401) {
                localStorage.removeItem("EReceiptuser");
                store.dispatch({
                  type: DISCONNECT,
                });
                this.props.history.push("/login");
              }
            });
        }
      },
    };
  };

  onsubClickRow = (row) => {
    return {
      onClick: () => {
        this.setState({
          selectedPart: "",
          isItem: false,
          itemChanged: false,
        });
        if (this.state.showPostModal === false) {
          console.log("Last Heat",row);
          this.setState(
            {
              selectedPart: row.Line_No,
              machiningTime: row.MachiningTime,
              grossWt: row.Gross_Weight,
              status: 0,
              msg: "",
              submsg: "",
              msg_type: false,
              partInfo: row,
              lotNoMandatory: row.LotNoMandatory,
              lotNo: row.Last_Heat_No,
              location: row.Location_Code,
              locationMandatory: row.Location_Code === "" ? true : false,
            },
            () => {
              if (this.state.partInfo.FormatType !== "Comment") {
                let ModalTitle = (
                  <div className="title">
                    <span className="modal-title" style={{ color: "black" }}>
                      <table width="100%">
                        <tbody>
                          <tr>
                            <td width="20%">
                              <b>Part #:</b>
                            </td>
                            <td width="80%">{this.state.partInfo.No}</td>
                          </tr>
                          <tr>
                            <td width="20%">
                              <b>Desc:</b>
                            </td>
                            <td width="80%">
                              {this.state.partInfo.PartDescription}
                            </td>
                          </tr>
                          <tr>
                            <td width="20%">
                              <b>PO-Desc:</b>
                            </td>
                            <td width="80%">
                              {this.state.partInfo.Description}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </span>
                  </div>
                );
                this.setState({ ModalTitle: ModalTitle }, () => {
                  this.handleOpenPostModal();
                });
              }
            }
          );
        }
      },
    };
  };

  onitemClickRow = (row) => {
    return {
      onClick: () => {
        console.log("Item Heat No",row);
        this.setState({
          selectedPO: "",
          selectedPart: "",
          selectedItem: row,
          isItem: true,
          PL_Count: 1,
        });
        if (this.state.showPostModal === false) {
          this.setState(
            {
              selectedPart: row.Line_No,
              machiningTime: row.MachiningTime,
              grossWt: row.Gross_Weight,
              status: 0,
              msg: "",
              submsg: "",
              msg_type: false,
              partInfo: row,
              location:row.Location_Code,
              lotNoMandatory:row.LotNoMandatory,
              locationMandatory:row.Location_Code === "" ? true : false,
            },
            () => {
              if (this.state.partInfo.FormatType !== "Comment") {
                let ModalTitle = (
                  <div className="title">
                    <span className="modal-title" style={{ color: "black" }}>
                      <table width="100%">
                        <tbody>
                          <tr>
                            <td width="20%">
                              <b>Part #:</b>
                            </td>
                            <td width="80%">{this.state.partInfo.No}</td>
                          </tr>
                          <tr>
                            <td width="20%">
                              <b>Desc:</b>
                            </td>
                            <td width="80%">
                              {this.state.partInfo.PartDescription}
                            </td>
                          </tr>
                          <tr>
                            <td width="20%">
                              <b>PO-Desc:</b>
                            </td>
                            <td width="80%">
                              {this.state.partInfo.Description}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </span>
                  </div>
                );
                this.setState({ ModalTitle: ModalTitle }, () => {
                  this.handleOpenPostModal();
                });
              }
            }
          );
        }
      },
    };
  };

  setRowClassName = (record) => {
    return record.No === this.state.selectedRow ? "clickRowStyl" : "";
  };

  setSubRowClassName = (record) => {
    return record.Line_No === this.state.selectedPart ? "clickRowStyl" : "";
  };

  setItemRowClassName = (record) => {
    return record.Document_No === this.state.selectedItem.Document_No && record.Line_No === this.state.selectedItem.Line_No
      ? "clickRowStyl"
      : "";
  };

  render() {
    const screenWidth = "'" + window.innerWidth + "px'";

    const customizeRenderEmpty = () => (
      <div style={{ textAlign: "center" }}>
        <CloseOutlined style={{ fontSize: 40, color: "red" }} />
        <>
          <h4>No Items found for '{this.state.itemValue}' </h4>
          <span>
            <h6>Please search with another keyword.</h6>
          </span>
        </>
      </div>
    );

    const customizeRenderMainEmpty = () => (
      <div style={{ textAlign: "center" }}>
        <CloseOutlined style={{ fontSize: 40, color: "red" }} />
        <>
          <h4>No Purchase Orders Found!</h4>
          <span>
            {/* <h6>Please Refresh to try again!</h6> */}
          </span>
        </>
      </div>
    );

    const customizeRenderNoLocation = () => (
      <div style={{ textAlign: "center" }}>
        <CloseOutlined style={{ fontSize: 40, color: "red" }} />
        <>
          <h4>Please Select a Division! </h4>
          <span>
            <h6>Please Select a Division First in order to see the Purchase Orders for that Division.</h6>
          </span>
        </>
      </div>
    );

    const columns = [
      {
        dataIndex: "No",
        title: <span id="pono">PO No</span>,
        width: "15vw",
        align: "left",
        sorter: (a, b) => {
          return a.No.localeCompare(b.No);
        },
        children: [
          {
            title: (
              <Input
                allowClear
                id="ponoInput"
                onChange={this.findNo}
                style={{ width: "8vw" }}
              />
            ),
            dataIndex: "No",
            width: "15vw",
          },
        ],
      },

      {
        dataIndex: "Posting_Description",
        title: <span id="podesc">PO Desc</span>,
        width: "20vw",
        align: "left",
        sorter: (a, b) => {
          return a.Posting_Description.localeCompare(b.Posting_Description);
        },
        children: [
          {
            title: (
              <Input
                id="podescInput"
                allowClear
                onChange={this.findDesc}
                style={{ width: "10vw" }}
              />
            ),
            dataIndex: "Posting_Description",
            width: "20vw",
          },
        ],
      },

      {
        dataIndex: "Buy_from_Vendor_Name",
        title: <span id="povendor">Vendor Name</span>,
        width: "25vw",
        align: "left",
        sorter: (a, b) => {
          return a.Buy_from_Vendor_Name.localeCompare(b.Buy_from_Vendor_Name);
        },
        children: [
          {
            title: (
              <Input
                id="povendorInput"
                allowClear
                onChange={this.findVendor}
                style={{ width: "10vw" }}
              />
            ),
            dataIndex: "Buy_from_Vendor_Name",
            width: "25vw",
          },
        ],
      },
      {
        dataIndex: "Status",
        title: <span id="postatus">Status</span>,
        width: "15vw",
        align: "left",
        sorter: (a, b) => {
          return a.Status.localeCompare(b.Status);
        },
        children: [
          {
            title: (
              <Input
                id="postatusInput"
                allowClear
                onChange={this.findStatus}
                style={{ width: "10vw" }}
              />
            ),
            dataIndex: "Status",
            width: "15vw",
          },
        ],
      },
    ];

    const subcolumns = [
      {
        dataIndex: "Line_No",
        title: "PO Line No",
        width: 20,
        align: "left",
        sorter: (a, b) => {
          return a.Line_No - b.Line_No;
        },
      },

      {
        dataIndex: "FormatType",
        title: "Type",
        width: 20,
        align: "left",
        sorter: (a, b) => {
          return a.FormatType.localeCompare(b.FormatType);
        },
      },

      {
        dataIndex: "Description",
        title: "Line Description",
        width: 50,
        align: "left",
        sorter: (a, b) => {
          return a.Description.localeCompare(b.Description);
        },
      },
      {
        dataIndex: "No",
        title: "Part No",
        width: 20,
        align: "left",
        sorter: (a, b) => {
          return a.No.localeCompare(b.No);
        },
      },
      {
        dataIndex: "PartDescription",
        title: "Part Description",
        width: 80,
        align: "left",
        sorter: (a, b) => {
          return a.PartDescription - b.PartDescription;
        },
      },

      {
        dataIndex: "Quantity",
        title: "Qty",
        width: 20,
        align: "left",
        sorter: (a, b) => {
          return a.Quantity - b.Quantity;
        },
      },

      {
        dataIndex: "Quantity_Received",
        title: "Qty Rcd",
        width: 20,
        align: "left",
        sorter: (a, b) => {
          return a.Quantity_Received - b.Quantity_Received;
        },
      },
      {
        dataIndex: "QtyOutstanding",
        title: "Qty Rem",
        width: 20,
        align: "left",
        sorter: (a, b) => {
          return a.QtyOutstanding - b.QtyOutstanding;
        },
      },
      {
        dataIndex: "Unit_of_Measure",
        title: "Unit",
        width: 20,
        align: "left",
        sorter: (a, b) => {
          return a.Unit_of_Measure.localeCompare(b.Unit_of_Measure);
        },
      },
    ];

    const itemcolumns = [
      {
        dataIndex: "PrimaryKeyCombo",
        title: "Key Field",
        hideInTable: true,
      },

      {
        dataIndex: "Document_No",
        title: <span id="itemdesc">PO No</span>,
        width: "10vw",
        align: "left",
        sorter: (a, b) => {
          return a.Document_No.localeCompare(b.Document_No);
        },
        children: [
          {
            title: (
              <Input
                id="itemdescInput"
                allowClear
                value={this.state.itemdesc}
                onChange={this.findItemDesc}
                style={{ width: "5vw" }}
              />
            ),
            dataIndex: "Document_No",
            width: "10vw",
          },
        ],
      },
      {
        dataIndex: "Buy_from_Vendor_Name",
        title: <span id="itemvendor">Vendor Name</span>,
        width: "10vw",
        align: "left",
        sorter: (a, b) => {
          return a.Buy_from_Vendor_Name.localeCompare(b.Buy_from_Vendor_Name);
        },
        children: [
          {
            title: (
              <Input
                id="itemvendorInput"
                allowClear
                value={this.state.itemvendor}
                onChange={this.findItemVendor}
                style={{ width: "7vw" }}
              />
            ),
            dataIndex: "Buy_from_Vendor_Name",
            width: "10vw",
          },
        ],
      },
      {
        dataIndex: "FormatType",
        title: <span id="itemtype">Type</span>,
        width: "10vw",
        align: "left",
        sorter: (a, b) => {
          return a.FormatType.localeCompare(b.FormatType);
        },
        children: [
          {
            title: (
              <Input
                id="itemtypeInput"
                value={this.state.itemtype}
                allowClear
                onChange={this.findItemType}
                style={{ width: "7vw" }}
              />
            ),
            dataIndex: "FormatType",
            width: "10vw",
          },
        ],
      },
      {
        dataIndex: "No",
        title: <span id="itempart">Part No</span>,
        width: "10vw",
        align: "left",
        sorter: (a, b) => {
          return a.No.localeCompare(b.No);
        },
        children: [
          {
            title: (
              <Input
                id="itempartInput"
                value={this.state.itempart}
                allowClear
                onChange={this.findItemPart}
                style={{ width: "7vw" }}
              />
            ),
            dataIndex: "No",
            width: "10vw",
          },
        ],
      },
      {
        dataIndex: "Description",
        title: <span id="itempartdesc">Part Description</span>,
        width: "15vw",
        align: "left",
        sorter: (a, b) => {
          return a.PartDescription - b.PartDescription;
        },
        children: [
          {
            title: (
              <Input
                id="itempartdescInput"
                value={this.state.itempartdesc}
                allowClear
                onChange={this.findItemPartDesc}
                style={{ width: "7vw" }}
              />
            ),
            dataIndex: "Description",
            width: "15vw",
          },
        ],
      },
      {
        dataIndex: "Quantity",
        title: <span id="itemqty">Qty</span>,
        width: "7vw",
        align: "left",
        sorter: (a, b) => {
          return a.Quantity - b.Quantity;
        },
        children: [
          {
            title: (
              <Input
                id="itemqtyInput"
                value={this.state.itemqty}
                allowClear
                onChange={this.findItemQty}
                style={{ width: "6vw" }}
              />
            ),
            dataIndex: "Quantity",
            width: "7vw",
          },
        ],
      },

      {
        dataIndex: "Quantity_Received",
        title: <span id="itemqtyrcd">Qty Rcd</span>,
        width: "7vw",
        align: "left",
        sorter: (a, b) => {
          return a.Quantity_Received - b.Quantity_Received;
        },
        children: [
          {
            title: (
              <Input
                id="itemqtyrcdInput"
                value={this.state.itemqtyrcd}
                allowClear
                onChange={this.findItemQtyRcd}
                style={{ width: "6vw" }}
              />
            ),
            dataIndex: "Quantity_Received",
            width: "7vw",
          },
        ],
      },
      {
        dataIndex: "QtyOutstanding",
        title: <span id="itemqtyrem">Qty Rem</span>,
        width: "7vw",
        align: "left",
        sorter: (a, b) => {
          return a.QtyOutstanding - b.QtyOutstanding;
        },
        children: [
          {
            title: (
              <Input
                id="itemqtyremInput"
                value={this.state.itemqtyrem}
                allowClear
                onChange={this.findItemQtyRem}
                style={{ width: "6vw" }}
              />
            ),
            dataIndex: "QtyOutstanding",
            width: "7vw",
          },
        ],
      },
      {
        dataIndex: "Unit_of_Measure",
        title: <span id="itemunit">Unit</span>,
        width: "5vw",
        align: "left",
        sorter: (a, b) => {
          return a.Unit_of_Measure.localeCompare(b.Unit_of_Measure);
        },
        children: [
          {
            title: (
              <Input
                id="itemunitInput"
                value={this.state.itemunit}
                allowClear
                onChange={this.findItemUnit}
                style={{ width: "4vw" }}
              />
            ),
            dataIndex: "Unit_of_Measure",
            width: "5vw",
          },
        ],
      },
    ];

    const FilterByPOInput = (
      <Input
        autoComplete="off"
        allowClear
        id="searchBarAnt"
        value={this.state.value}
        onChange={this.searchValue}
        disabled={this.state.noPOdata ? true : false}
      />
    );

    const FilterByPOLineInput = (
      <Input
        autoComplete="off"
        style={{ marginBottom: "20px" }}
        allowClear
        id="searchBarAnt"
        value={this.state.subValue}
        onChange={this.searchSubValue}
        disabled={this.state.noPOsubdata ? true : false}
      />
    );

    const FilterByItemInput = (
      <Search
        allowClear
        id="searchBarAnt2"
        enterButton="Search"
        value={this.state.itemValue}
        onChange={this.changeItemValue}
        onSearch={this.searchItems}
        disabled={this.state.isItemLoading ? true : false}
      />
    );

    return (
      <PageContainer>
        <div style={{ minWidth: screenWidth }}>
          <Spin spinning={this.state.isloading}>
            <div style={{ minWidth: screenWidth }}>
              {FilterByPOInput}
              <div style={{ marginBottom: "20px" }}></div>
              <ConfigProvider
                renderEmpty={this.props.division!='' && this.props.division!=undefined?this.state.noPOdata && customizeRenderMainEmpty:this.state.noPOdata && customizeRenderNoLocation}
              >
                <div id="maintable">
                  <ProTable
                    showHeader={true}
                    columns={columns}
                    scroll={{ y: 600, x: screenWidth }}
                    options={{
                      reload: false,
                      fullScreen: false,
                    }}
                    search={false}
                    params={this.state.value}
                    postData={(data) => this.handleData(data)}
                    dataSource={this.state.POdataSource}
                    request={(params) => {
                      this.setState({
                        paramList: params,
                      });
                      return Promise.resolve({
                        data: this.state.POdataSource,
                        success: true,
                      });
                    }}
                    rowKey="No"
                    toolBarRender={() => (
                      <>
                        <Button
                          type="primary"
                          className="mr-2"
                          disabled={this.props.division==''||this.props.division==undefined}
                          onClick={this.handleOpenItemModal}
                        >
                          Search By Item
                        </Button>
                        <Tooltip placement="top" title="Refresh">
                          <ReloadOutlined onClick={() => this.Reload() } disabled={this.props.division==''||this.props.division==undefined} />
                        </Tooltip>
                      </>
                    )}
                    onRow={this.onClickRow}
                    rowClassName={this.setRowClassName}
                    pagination={false}
                  />
                </div>
              </ConfigProvider>
            </div>
          </Spin>
        </div>
        <div>
          <Modal
            visible={this.state.showSubModal}
            onCancel={this.handleCloseSubModal}
            className={window.innerWidth > 1000 ? "fullModal" : "fullestModal"}
            maskClosable={false}
            bodyStyle={{
              top: 0,
              height: window.innerWidth > 1000 ? "100vh" : "auto",
            }}
            width="90vw"
            footer={null}
          >
            <div>
              <Button
                key="1"
                disabled={this.state.isposting ? true : false}
                onClick={this.handleCloseSubModal}
              >
                BACK
              </Button>
            </div>
            <br />
            <Spin spinning={this.state.isSubloading}>
              <div id="subtable">
                {FilterByPOLineInput}

                <ProTable
                  showHeader={true}
                  columns={subcolumns}
                  scroll={{ y: 360, x: screenWidth }}
                  options={{
                    reload: false,
                    fullScreen: false,
                  }}
                  dataSource={this.state.subdataSource}
                  search={false}
                  rowKey="Line_No"
                  toolBarRender={(action) => (
                    <>
                      <Tooltip placement="top" title="Refresh">
                        <ReloadOutlined onClick={() => this.subReload()} />
                      </Tooltip>
                    </>
                  )}
                  onRow={this.onsubClickRow}
                  rowClassName={this.setSubRowClassName}
                  pagination={false}
                />
              </div>
            </Spin>
          </Modal>
        </div>

        <div>
          <Modal
            visible={this.state.showItemModal}
            onCancel={this.handleCloseItemModal}
            maskClosable={false}
            className={window.innerWidth > 1000 ? "fullModal" : "fullestModal"}
            bodyStyle={{
              top: 0,
              height: window.innerWidth > 1000 ? "100vh" : "auto",
            }}
            width="100vw"
            footer={null}
          >
            <div>
              <Button
                key="1"
                disabled={this.state.isposting ? true : false}
                onClick={this.handleCloseItemModal}
              >
                BACK
              </Button>
            </div>
            <br />
            <Spin spinning={this.state.isItemLoading}>
              <div id="itemtable">
                {FilterByItemInput}
                <ConfigProvider
                  renderEmpty={this.state.noItemData && customizeRenderEmpty}
                >
                  <ProTable
                    showHeader={true}
                    columns={itemcolumns}
                    scroll={{ y: "50vh", x: screenWidth }}
                    options={{
                      reload: false,
                      fullScreen: false,
                    }}
                    dataSource={this.state.itemdataSource}
                    search={false}
                    rowKey="@odata.etag"
                    toolBarRender={(action) => (
                      <>
                        <Tooltip placement="top" title="Refresh">
                          <ReloadOutlined
                            onClick={() =>
                              this.searchItems(this.state.itemValue)
                            }
                          />
                        </Tooltip>
                      </>
                    )}
                    onRow={this.onitemClickRow}
                    rowClassName={this.setItemRowClassName}
                    pagination={false}
                  />
                </ConfigProvider>
              </div>
            </Spin>
          </Modal>
        </div>

        <div>
          <Modal
            centered
            title={this.state.ModalTitle}
            visible={this.state.showPostModal}
            onCancel={this.handleClosePostModal}
            maskClosable={false}
            bodyStyle={{ top: 0, fontSize: "large" }}
            width={window.innerWidth > 1000 ? "40vw" : "80vw"}
            zIndex="2100"
            okButtonProps={{ disabled: this.state.isposting ? true : false }}
            cancelButtonProps={{
              disabled: this.state.isposting ? true : false,
            }}
            footer={[
              <Button
                key="1"
                disabled={this.state.isposting ? true : false}
                onClick={this.handleClosePostModal}
              >
                Cancel
              </Button>,
              this.state.PL_Count === 1 ? "" : <></>,
              this.state.PL_Count>0?<Button
                key="4"
                disabled={this.state.isposting ? true : false}
                type="primary"
                onClick={this.handlePost}
              >
                {" "}
                Post{" "}
              </Button>:<></>,
            ]}
          >
            <div>
              {this.state.PL_Count === 0 ? (
                <div>
                  <h4>
                    {this.state.partInfo.No} No Purchase Lines available to be
                    received.
                  </h4>
                </div>
              ) : (
                <div>
                  <div>
                    <span>
                      {this.state.status === 0 ? (
                        ""
                      ) : this.state.status === 1 ? (
                        <span>
                          <Spin spinning={this.state.isposting} />
                          Please Wait...
                        </span>
                      ) : this.state.msg_type ? (
                        <span style={{ color: "green" }}>
                          <img
                            src={Success}
                            alt="success"
                            height="20px"
                            width="20px"
                          />
                          SUCCESS...
                        </span>
                      ) : (
                        <span
                          style={{ color: "red" }}
                          onClick={this.handleOpenModalMessage}
                        >
                          <img
                            src={Error}
                            alt="error"
                            height="20px"
                            width="20px"
                          />{" "}
                          ERROR...
                        </span>
                      )}
                    </span>
                    <span>
                      {this.state.emailSent === 0 ? (
                        ""
                      ) : this.state.emailSentSuccessful === 0 ? (
                        <span style={{ color: "green", float: "right" }}>
                          <img
                            src={Success}
                            alt="success"
                            height="20px"
                            width="20px"
                          />
                          EMAIL SENT...
                        </span>
                      ) : (
                        <span style={{ color: "red", float: "right" }}>
                          <img
                            src={Error}
                            alt="error"
                            height="20px"
                            width="20px"
                          />{" "}
                          EMAIL NOT SENT...
                        </span>
                      )}
                    </span>
                  </div>

                  <table>
                    <tbody>
                      <tr>
                        <td>Quantity Ordered:</td>
                        <td> {this.state.partInfo.Quantity}</td>
                      </tr>
                      <tr>
                        <td>Quantity Remaining:</td>
                        <td> {this.state.partInfo.QtyOutstanding}</td>
                      </tr>
                      <tr>
                        <td> Total Quantity Received: </td>
                        <td>{this.state.partInfo.Quantity_Received}</td>
                      </tr>
                      <tr>
                        <td> Receipt Date: </td>
                        <td>
                          <DatePicker
                            style={{ border: "2px black solid" }}
                            getPopupContainer={(triggerNode) => {
                              return triggerNode.parentNode;
                            }}
                            allowClear={false}
                            value={this.state.date}
                            onChange={(date, dateString) =>
                              this.onDateChange(date, dateString)
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>New Quantity to Receive : </td>
                        <td>
                          <input
                            type="number"
                            style={{ width: "5.2em" }}
                            placeholder="0"
                            value={
                              this.state.newQuantity
                                ? this.state.newQuantity
                                : ""
                            }
                            onChange={this.handleQtyChange}
                            disabled={this.state.isposting ? true : false}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Heat No :</td>
                        <td>
                          <input
                            title={
                              this.state.isposting || !this.state.lotNoMandatory
                                ? "Heat No is not required"
                                : false
                            }
                            type="string"
                            style={{
                              width: "5.2em",
                              border: "2px black solid",
                            }}
                            value={this.state.lotNo ? this.state.lotNo : ""}
                            onChange={this.handleLotNoChange}
                            disabled={
                              this.state.isposting || !this.state.lotNoMandatory
                                ? true
                                : false
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Location: </td>
                        <td>
                          <Select
                            showSearch
                            value={this.state.location}
                            style={{ width: "10vw", border: "2px black solid" }}
                            onChange={(e) => this.handleChangeLoaction(e)}
                            // disabled={
                            //   this.state.locationMandatory ? false : true
                            // }
                            options={this.state.allLocations}
                            filterOption={(input, option) =>
                              option.label
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            getPopupContainer={(triggerNode) => {
                              return triggerNode.parentNode;
                            }}
                          ></Select>
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={2}
                          style={{ color: "red", fontSize: "15px" }}
                        >
                          {this.state.submsg}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </Modal>
        </div>

        <div>
          <Modal
            centered
            title="Error"
            visible={this.state.showModalMessage}
            onCancel={this.handleCloseModalMessage}
            maskClosable={false}
            zIndex="2150"
            bodyStyle={{ top: 0 }}
            footer={[
              <Button key="1" onClick={this.handleCloseModalMessage}>
                Cancel
              </Button>,
              <Button
                key="2"
                type="primary"
                onClick={this.handleOpenModalEmail}
              >
                Email Error{" "}
              </Button>,
            ]}
          >
            <div>
              <table width="100%">
                <tbody>
                  <tr>
                    <td width="10%">
                      <b>PO #:</b>
                    </td>
                    <td width="90%"> {this.state.partInfo.Document_No}</td>
                  </tr>
                  <tr>
                    <td width="10%">
                      <b>Line #:</b>
                    </td>
                    <td width="90%">{this.state.partInfo.Line_No}</td>
                  </tr>
                  <tr>
                    <td width="10%">
                      <b>Part #:</b>
                    </td>
                    <td width="90%"> {this.state.partInfo.No}</td>
                  </tr>
                  <tr>
                    <td width="10%">
                      <b>Error:</b>
                    </td>
                    <td width="90%"> {this.state.msg}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal>
        </div>

        <div>
          <Modal
            centered
            className={window.innerWidth > 1000 ? "fullModal" : "fullestModal"}
            visible={this.state.showModalEmail}
            width="80vw"
            maskClosable={false}
            bodyStyle={{
              fontSize: "14px",
              height: window.innerWidth > 1000 ? "100vh" : "auto",
            }}
            zIndex="2200"
            onCancel={this.handleCloseModalEmail}
            footer={null}
          >
            <div>
              <span>
                {this.state.emailSent === 0 ? (
                  ""
                ) : this.state.emailSentSuccessful === 0 ? (
                  <div>
                    <img
                      src={Success}
                      alt="success"
                      height="20px"
                      width="20px"
                    />{" "}
                    <b>Email Sent Successfully..</b>
                  </div>
                ) : (
                  <div>
                    <img src={Error} alt="error" height="20px" width="20px" />
                    <b> Email Not Sent! TRY Again..</b>
                  </div>
                )}
              </span>

              <Form
                ref={this.formRef}
                name="control-ref"
                layout="vertical"
                initialValues={{
                  Subject: this.state.emailSubject,
                  Message: this.state.emailBody,
                  Recipient: this.state.helpDeskEmail,
                }}
                onFinish={this.onFinish}
              >
                <Form.Item
                  name="Recipient"
                  label="To"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item name="Cc" label="Cc">
                  <Input />
                </Form.Item>
                <Form.Item name="Bcc" label="Bcc">
                  <Input />
                </Form.Item>
                <Form.Item name="Subject" label="Subject">
                  <Input disabled />
                </Form.Item>
                <Form.Item name="Message" label="Message">
                  <TextArea rows={25} cols={15} />
                </Form.Item>

                <Form.Item style={{ float: "right" }}>
                  <Button
                    type="primary"
                    className="m-2"
                    htmlType="submit"
                    loading={this.state.isSending ? true : false}
                  >
                    {this.state.isSending ? "Sending" : "Send Email"}
                  </Button>
                  <Button
                    htmlType="button"
                    className="m-2"
                    disabled={this.state.isSending ? true : false}
                    onClick={this.onReset}
                  >
                    Reset
                  </Button>
                  <Button
                    htmlType="button"
                    className="m-2"
                    disabled={this.state.isSending ? true : false}
                    onClick={this.handleCloseModalEmail}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Modal>
        </div>

        <div>
          <Modal
            centered
            title="Logging out...."
            visible={this.state.showSignoutModal}
            onCancel={this.closeSignoutModal}
            maskClosable={false}
            zIndex="4000"
            bodyStyle={{ top: 0 }}
            footer={[
              <Button key="1" type="primary" onClick={this.handleKeepLoggedIn}>
                Stay
              </Button>,
              <Button key="2" type="primary" danger onClick={this.handleLogout}>
                Logout
              </Button>,
            ]}
          >
            <div>
              <h4>You are about to be logged out!</h4>
              <h5>Do you want to stay?</h5>
            </div>
          </Modal>
        </div>
      </PageContainer>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  division:state.division
});

export default withRouter(connect(mapStateToProps)(WebReceipt));
