import React from "react";
import { withRouter } from 'react-router-dom';
import { Button, Input, Form, Space, Select } from 'antd';
import './register-form.styles.css';

const { Option } = Select;
const validateMessages = {
	required: '${label} is required!',
	types: { email: 'Not a valid email format!' }
};
const required = [{ required: true }];
const email = [{ required: true, type: 'email' }];
const password = [{ required: true }, { min: 6, message: 'Password must be minimum 6 characters.' }];

/* IMPORTANT PROPS
	onFinish = onFinish method of the form, 
	btnLoading = handles the button loading status
*/
class RegisterForm extends React.Component {
	form = React.createRef();

	componentDidUpdate(prevProps) {
		this.resetInputFields(prevProps);
	}

    render() {
        const { btnLoading } = this.props;
        return (
            <Form id="register-form" 
                ref={ this.form }
                layout="vertical"
                validateMessages={ validateMessages }
                onFinish={ this.props.onFinish }
            >
                <Input.Group Compact className="input-columns">
                    <Form.Item hasFeedback label="Username" name="username" rules={ required }>
                        <Input placeholder="Enter a username"/>
                    </Form.Item>
                    <Form.Item hasFeedback label="Invitation code" name="invitationcode" rules={ required }>
                        <Input.Password visibilityToggle={false} placeholder="Enter your invitation code"/>
                    </Form.Item>
                </Input.Group>
                <Form.Item hasFeedback label="Company" name="company" rules={ required }>
                    <Select allowClear placeholder="Select an company type">
                    <Option value="Haven Employee">Haven Employee</Option>
                        <Option value="McKenzie Employee">McKenzie Employee</Option>
                        <Option value="Third Party Contractor">Third Party Contractor</Option>
                    </Select>
                </Form.Item>
                <Form.Item hasFeedback label="Email" name="email" rules={ email }>
                    <Input placeholder="Enter your email address" />
                </Form.Item>
                <Form.Item hasFeedback label="Password" name="password" rules={ password }>
                    <Input.Password visibilityToggle={false} placeholder="Enter a password. Must be 6 digits or longer"/>
                </Form.Item>
                <Form.Item className="space-up">
                    <Space className="button-group">
                        <Button htmlType="submit" className="primary-btn" loading={ btnLoading }>
                            Signup
                        </Button>
                        <Button htmlType="button" className="colorless-btn" onClick={ this.handleReset }>
                            Reset
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        );
    }

    resetInputFields = (prev, curr = this.props) => {
		if (prev.btnLoading && !curr.btnLoading) {
			this.form.current.setFieldsValue({ invitationcode: null, email: null });
			this.form.current.validateFields();
		}
	}

    handleReset = () => this.form.current.resetFields();
}

export default withRouter(RegisterForm);