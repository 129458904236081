import React from 'react';
import './App.css';
import { Switch, Route, Redirect } from 'react-router-dom';
import Homepage from './pages/homepage/homepage.component';
import Loginpage from './pages/login/login.page';
import RequestResetPasswordPage from './pages/resetpasswordpage/reset-password.page';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { DISCONNECT } from '../src/redux/types';
import { store } from '../src/redux/store';
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
const mapStateToProps = (state) => ({
  user: state.user,
});

function App({ history, user }) {
  var isUserThere = false;
  console.log("User in Ap.js",user);
  console.log(localStorage.getItem('EReceiptuser'));
  if (user) {
    const expiryTime = localStorage.getItem('EReceiptuser')
    const today = new Date()
    if (new Date(expiryTime) <= new Date(today) || expiryTime === '' || expiryTime == null) {
      localStorage.removeItem('EReceiptuser');
      localStorage.removeItem('beartoken')
      store.dispatch({
        type: DISCONNECT,
      });
      isUserThere = false
    } else {
      isUserThere = true
    }
  } else {
    isUserThere = false
  }
  return (
    <div>
      <Switch>
        <Route
          exact
          path={['/', '/home']}
          render={() =>
            isUserThere ? (
              <Homepage />
            ) : (
              <Redirect to={{ pathname: '/login' }} />
            )
          }
        />
        <Route path={['/login', '/register', '/request-reset']}>
          {isUserThere ? <Redirect to={{ pathname: '/' }} /> : <Loginpage />}
        </Route>
        <Route path="/ResetPassword" render={() => <RequestResetPasswordPage />} />
      </Switch>
    </div>

  )
}

export default withRouter(connect(mapStateToProps)(App));
