import axios from 'axios';
import { notification } from 'antd';
import address from '../../components/address.component';

export function ValidateTokenService(token) {
  return axios
    .post(`${address}/auth/validateToken`, { token })
    .then(({  }) => {
      return false;
    })
    .catch((error) => {
      return true
    });
}

export function UpdatePasswordService(password, token) {
  return axios
    .post(`${address}/auth/updateUserPass`, { password, token })
    .then(({  }) => {
      notification.success({
        message: "Password Reset Success!",
      });
      return;
    })
    .catch(() => {
      notification.error({
        message: "Password Reset Failed!",
      });
      return;
    });
}
