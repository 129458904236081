import React from 'react';
import DataGridTest3 from '../../components/datagrid/Ant/AntVersion';
import { Layout } from 'antd';
import Header from '../../components/header/header.component';
import FooterComponent from '../../components/footer/footer.component';
const { Content } = Layout;

const width = "'" + window.innerWidth + "px'";
const HomePage = () => (
  <Layout style={{ minWidth: width,height: "100vh"  }}>
    <Header />
    <Layout>
      {/*  <SideBar/> */}
      <Layout className="site-layout">
        <Content style={{ margin: '10px 10px' }}>
          <div
            className="site-layout-background"
            style={{ padding: 24, paddingTop: 0, minHeight: '80vh' }}
          >
            <DataGridTest3 />
          </div>
        </Content>
      </Layout>
    </Layout>
  </Layout>
);

export default HomePage;
