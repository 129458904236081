import React from "react";
import { Button, Navbar, Nav, Container } from 'react-bootstrap';
import logo from '../../assets/images/haven-white-logo.png';
import { connect } from 'react-redux';
import './header.styles.css';
import { withRouter } from 'react-router-dom';
import { store } from '../../redux/store';
import { Avatar, Menu, Dropdown, Select, notification } from "antd";
import { DISCONNECT,UPDATE_DIVISION,CONNECT_WITH_SSO} from '../../redux/types';
import axios from "axios";
import address, { DEV_MODE } from "../address.component";
import HelpButton from '../../components/header/HelpScreenshotButton/HelpScreenshotButton.component';
const { Option } = Select;
async function disconnectWithSSO(history) {
  console.log('DisconnectWithSSO');
  store.dispatch({
    type: DISCONNECT,
  });
  history.push('/');
  localStorage.removeItem('EReceiptuser');
  localStorage.removeItem('beartoken');
}



class Header extends React.Component {
  state = 
  {
    selectedDivision: this.props.division?this.props.division:"Select a Division",
    divisionClicked:false,
    divisions:[]
  }

  componentDidMount() {
    const fetchDivisions = async () => {
      const result = await this.getDivisions();
      if (result) {
        this.setState({ divisions: result },()=>{
          console.log(this.state.divisions);
        });
      }
    };
    fetchDivisions();
  }

  getDivisions = async () => {
    const response = await axios.get(address + "/nav/getDivisions");
    return response.data;
  };

  onSelectDivision = (selectedDivision) => {
    let user=this.props.user;
    this.setState(
      {
        selectedDivision,
       divisionClicked: false,
      },
      () =>{
        console.log(this.state.selectedDivision)      
    
    store.dispatch({
      type: UPDATE_DIVISION,
      division: this.state.selectedDivision,
    });
    // store.dispatch({
    //   type: DISCONNECT,
    // });
    // store.dispatch({
    //   user,
    //   type: CONNECT_WITH_SSO,
    // });
  });
  };

  onClickStopProg = (e) => {
    e.stopPropagation();
  };

  onDivisionClick = () => {
    this.setState({ divisionClicked: true });
  };

  render(){
    return(
  <Navbar style={{ backgroundColor: 'black' }} expand="sm">
    <Navbar.Brand href="/">
      <div
        style={{
          width: '350px',
          height: '100%',
          textAlign: 'center',
          margin: '-10px 0',
        }}
      >
        <img
          alt=""
          src={logo}
          width="100%"
          height="100%"
          className="d-inline-block align-top"
        />
      </div>
    </Navbar.Brand>
    <Navbar.Collapse
              id="responsive-navbar-nav"
              style={{ textAlign: "center", marginRight:0 }}
            >
              <Nav  style={{ width: "100%",height:"100%",color:"white",margin:"auto"}}>
                <div
                  onClick={this.onDivisionClick}
                  className="d-flex align-items-center DivisionSelect"
                  style={{ width: "100%", alignContent:"center",alignSelf:"center",margin:"auto"}}
                >
                   {this.props.user?this.state.divisionClicked ? (
                    <Select
                      
                      defaultOpen="true"
                      autoFocus={true}
                      listHeight={window.innerHeight * 0.7}
                      placeholder="Select a Division"
                      optionFilterProp="children"
                      onClick={this.onClickStopProg}
                      onSelect={this.onSelectDivision}
                      value={this.props.division}
                      size={"large"}
                      style={{
                        width: "100%",
                        fontSize: "3rem",
                        
                        // height:"100%"
                      }}
                      // onSearch={this.onSearch}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onDropdownVisibleChange={() =>
                        this.setState({ divisionClicked: false })
                      }
                    >
                      {this.state.divisions.map((item) => {
                        return (
                          <Option
                            style={{ fontSize: "2.6rem",  }}
                            className="DivisionSelect"
                            key={item.division}
                            value={item.division}
                          >
                            {item.division}
                          </Option>
                        );
                      })}
                    </Select>
                  ) : (
                    <div
                      style={{
                        color: "white",
                        fontSize: "2.8rem",
                        fontFamily: `Roboto, sans-serif`,
                        background: "#d32f2f",
                        padding: "0.25rem",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                    >
                      <p style={{marginBottom:0}}>{this.state.selectedDivision}</p>
                    </div>
                  ):""}{" "}
                </div>
              </Nav>
        </Navbar.Collapse>

    {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
    <Navbar.Collapse className="justify-content-end" >
      <Navbar.Text
        style={{
          color: 'white',
          marginRight: '20px',
          fontSize: "3rem"
        }}
      >
        {DEV_MODE ? "TEST" : "PROD"}
        {/* TEST */}
      </Navbar.Text>
      {this.props.user ? (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1">
                <Button
                  size="lg"
                  variant="danger"
                  onClick={() => disconnectWithSSO(this.props.history)}
                >
                  Sign out
                </Button>
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <Avatar
            style={{
              backgroundColor: '#1E90FF',
              marginRight: '20px',
              fontSize: "3rem",
            }}
            size={70}
          >
            {/* {user.adUser.hasOwnProperty('givenName') ? (user.adUser.hasOwnProperty('sn') ? user.adUser.givenName[0].charAt(0) + user.adUser.sn[0].charAt(0) : user.adUser.givenName[0].charAt(0) + '-') : (user.adUser.hasOwnProperty('sn') ? '-' + user.adUser.sn[0].charAt(0) : '--')}
        {} */}
            {this.props.user.hasOwnProperty('fullName')
              ? this.props.user.fullName.charAt(0).toUpperCase() +
                (this.props.user.fullName.match(/\b(\w)/g)[1] || '')
              : '--'}
          </Avatar>
        </Dropdown>
      ) : (
        ''
      )}

      <div style={{ marginRight: '20px' }}>
        <HelpButton />
      </div>
    </Navbar.Collapse>
  </Navbar>

)
      }
    };

const mapStateToProps = (state) => ({
  user: state.user,
  division: state.division,
});

export default withRouter(connect(mapStateToProps)(Header));

// <Button size="sm" variant="danger" onClick={() => disconnectWithSSO(history)}>Sign out</Button>
// <NavDropdown.Item style={{ size: '10px' }}></NavDropdown.Item>
