import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import html2canvas from 'html2canvas';
import { Select, notification } from 'antd';
import config from '../../config';
import { MailOutlined } from '@ant-design/icons';
import axios from 'axios';
import address from '../../address.component';
import './HelpScreenshotButton.styles.css';
import { Dropdown } from 'primereact/dropdown';
import { withRouter } from "react-router-dom";
import { DISCONNECT } from '../../../redux/types';
import { store } from '../../../redux/store';
import { connect } from 'react-redux';
const { Option } = Select;

class HelpScreenshotButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      helpModalVisible: false,
      helpSelectedReason: '',
      screenshotData: null,
      NAVCompany: '',
    };
  }

  componentDidMount() {
    axios
      .post(`${address}/nav/GetNavCompany`,{},{
        headers:{
          'Authorization' : localStorage.getItem('beartoken')
        }
      })
      .then((res) => {
        this.setState({ NAVCompany: res.data });
      })
      .catch((error) => {if(error.response.status === 401){
        localStorage.removeItem('EReceiptuser');
        store.dispatch({
          type: DISCONNECT,
        });
        this.props.history.push('/login')
      }});
  }

  openNotification = () => {
    notification.open({
      message: 'Email Sent',
      description:
        'A screenshot has been sent to support to troubleshoot the issue.',
      icon: <MailOutlined style={{ color: '#108ee9' }} />,
      style: {
        width: '40vw',
      },
    });
  };

  generateScreenshot = (modalHandler, screenshotDataHandler) => {
    html2canvas(document.body).then(function (canvas) {
      var data = canvas.toDataURL();
      modalHandler(true);
      screenshotDataHandler(data);
    });
  };

  sendEmail = () => {
    let reason = this.state.helpSelectedReason;
    var data = this.state.screenshotData;
    var company = this.state.NAVCompany;
    var recipient = config.emailRecipients;
    var mail = '';

    this.openNotification();

    mail += 'Client: ';
    mail += 'Reason: ' + reason;
    mail += 'NAV Company: ' + company;
    var parameters = {
      mail: mail,
      recipient: recipient,
      base64Screenshot: data,
      division:this.props.division
    };

    axios
      .post(`${address}/nav/sendHelpEmail`, parameters,{
        headers:{
          'Authorization' : localStorage.getItem('beartoken')
        }
      })
      .then((res) => {})
      .catch((error) => {if(error.response.status === 401){
        localStorage.removeItem('EReceiptuser');
        store.dispatch({
          type: DISCONNECT,
        });
        this.props.history.push('/login')
      }});
  };

  helpFooter = () => {
    return [
      <Button
        className="helpModalButton"
        key="1"
        onClick={() => this.setState({ helpModalVisible: false })}
      >
        {' '}
        Cancel{' '}
      </Button>,
      <Button
        className="helpModalButton"
        key="2"
        type="primary"
        onClick={() =>
          this.setState({ helpModalVisible: false }, () => {
            this.sendEmail();
          })
        }
      >
        {' '}
        Send{' '}
      </Button>,
    ];
  };

  helpTitle = () => {
    return (
      <div style={{ fontSize: '3rem' }}>
        Do you want to send a screenshot to the help desk?
      </div>
    );
  };

  handleReasonChange = (value) => {
    this.setState({ helpSelectedReason: value });
  };

  selectedReasonTemplate = (option, props) => {
    if (option) {
      return (
        <div>
          <h1 className="selectedReason">{option.value}</h1>
        </div>
      );
    }

    return <h1 className="selectedReason">{props.placeholder}</h1>;
  };

  reasonOptionTemplate = (option) => {
    return (
      <div>
        <h1 className="reasonItem">{option.label}</h1>
      </div>
    );
  };

  render() {
    return (
      <div>
        <Dialog
          id="helpModal"
          header="Help Request"
          visible={this.state.helpModalVisible}
          style={{ width: '80vw' }}
          onHide={() => this.setState({ helpModalVisible: false })}
          dismissableMask={true}
          footer={this.helpFooter}
        >
          <Dropdown
            id="reasonDropdown"
            value={this.state.helpSelectedReason}
            valueTemplate={this.selectedReasonTemplate}
            itemTemplate={this.reasonOptionTemplate}
            optionLabel="label"
            panelStyle={{ zIndex: '3000' }}
            options={[
              {
                label: "Can't find PO",
                value: "Can't find PO",
              },
              { label: 'Other', value: 'Other' },
            ]}
            onChange={(e) => {
              this.handleReasonChange(e.value);
            }}
            placeholder="Select a Reason"
          />
          <h1>{'Company: ' + this.state.NAVCompany}</h1>
        </Dialog>
        {/*<Modal
          center
          title={this.helpTitle()}
          footer={this.helpFooter()}
          onCancel={() => this.setState({ helpModalVisible: false })}
          onHide={() => this.setState({ helpModalVisible: false })}
          destroyOnClose
          visible={this.state.helpModalVisible}
          maskClosable={false}
          width={window.innerWidth > 1280 ? "80vw" : "90vw"}
        >

                    <p style={{ fontSize: "3rem" }}>Reason</p>
          <Select
            listHeight={window.innerHeight * 0.7}
            style={{
              width: window.innerWidth > 1280 ? "80vw" : "90vw",
              fontSize: "3rem",
            }}
            onChange={(value) => this.handleReasonChange(value)}
          >
            <Option style={{ fontSize: "3rem" }} value="Can't find MO">
              Can't find MO
            </Option>
            <Option style={{ fontSize: "3rem" }} value="Can't find WORKCENTER">
              Can't find WORKCENTER
            </Option>
            <Option style={{ fontSize: "3rem" }} value="Others">
              Others
            </Option>
          </Select>
          <h1>{"Company: " + this.state.NAVCompany}</h1> 
        </Modal> */}

        <Button
          className="p-button-danger"
          style={{ fontSize: "3rem" }}
          size="lg"
          onClick={() => {
            this.generateScreenshot(
              (value) => {
                this.setState({ helpModalVisible: value });
              },
              (value) => {
                this.setState({ screenshotData: value });
              }
            );
          }}
        >
          Help
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  division: state.division,
});

export default withRouter(connect(mapStateToProps)(HelpScreenshotButton));
