import axios from "axios";
import { notification } from "antd";
import { store } from "../../redux/store";
import { CONNECT_WITH_SSO } from "../../redux/types";
import address, { CONST_VERSION } from "../../components/address.component";

export function LoginService(input) {
  return axios
    .post(`${address}/auth/login`, input)
    .then(({ data: result }) => {
      const today = new Date();
      const expirydate = new Date(
        new Date(today).setHours(today.getHours() + 12)
      );
      localStorage.setItem("EReceiptuser", expirydate);
      localStorage.setItem("beartoken", result.token);
      store.dispatch({
        type: CONNECT_WITH_SSO,
        user: result.msg,
      });
      return true;
    })
    .catch((error) => {
      const errorMsg = error?.response?.data?.msg;
      if (
        errorMsg === "Account not valid" ||
        errorMsg === "Wrong Email address or Password"
      ) {
        notification.error({
          message: "Invalid Login Info",
          description:
            "Your email or password is incorrect. \r\n Please try again",
        });
      } else if (errorMsg === "Account just Disabled") {
        notification.error({
          message: "Too Many Login Attempts",
          description:
            "Exceeded the number of login attempts! \r\n This Account will be disabled for next 24 hours",
        });
      } else if (errorMsg === "Account Disabled") {
        notification.error({
          message: "Account Disabled",
          description:
            "This account has been disabled and is currently not accessible",
        });
      }
    });
}

export function RegisterService(input) {
  return axios
    .post(`${address}/auth/registernewuser`, input)
    .then(({ }) => {
      notification.success({
        message: "Registation success",
        description: "Your registration has been successfully completed",
      });
      return true;
    })
    .catch((error) => {
      const errorMsg = error?.response?.data?.msg;
      if (errorMsg === "Invaild Invitation Code") {
        notification.error({
          message: "Invaild Code",
          description: "Your invitation code is invalid. Please try again",
        });
      } else if (errorMsg === "Email is already registered") {
        notification.error({
          message: "Email is already registered",
          description:
            "This e-mail address has already been registered. Login or use Forgot Password to reset your password",
        });
      }
      return false;
    });
}

export function RequestResetService(email) {
  return axios
    .post(`${address}/auth/validateEmail`, email)
    .then(({ data: result }) => {
      notification.success({
        message: "Email Sent",
        description:
          "An email has been sent to you with the instructions to reset your password",
      });
      return true;
    })
    .catch((error) => {
      const errorMsg = error?.response?.data?.msg;
      if (errorMsg === "Account not valid") {
        notification.error({
          message: "Invalid Account",
          description: "Your email is not recognized. Please try again",
        });
      } else if (errorMsg === "Account Disabled") {
        notification.error({
          message: "Account Disabled",
          description:
            "Your account is disabled and cannot request password reset",
        });
      }
      return false;
    });
}
